import React from 'react'
import { DUMMY_RESUME_URL } from "../constants/Resume";

const Resume = () => {
    return (
        <div className='bg-[#EFF7F7] rounded-[32px] px-[40px] py-11 w-full h-96 overflow-auto scrollbar-hide'>
            <img src={DUMMY_RESUME_URL} className='shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] rounded-lg' alt="resume" />
        </div>
    )
}

export default Resume