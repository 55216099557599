import React, { useState } from 'react';
import Promo from './components/Promo';
import SelectWithLabelOption from './components/SelectWithLabelOption';
import { AREA_OPTIONS, LANGUAGE_OPTIONS, PRICE_PER_LESSON_OPTIONS } from './constants/SearchTutor';
import SearchTutorInput from './components/SearchTutorInput';
import TutorCard from './components/TutorCard';
import Tutors from "./data/Tutors.json"
import BookingModal from './components/BookingModal';
import CustomModal from '../../Components/CustomModal';
import CustomSelect from '../../Components/CustomSelect';
import { Footer } from '../../Components/Footer';
import OrangeButton from '../../Components/buttons/orange-button';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeroContainer from '../../Components/ui/hero-container';


const steps = [
    {
        icon: <img src='/images/icons/binoculars.svg' alt='binoculars' className="w-12 h-12 text-[#5CD2C6]" />,
        text: "Find a suitable tutor"
    },
    {
        icon: <img src='/images/icons/headset.svg' alt='headset' className="w-12 h-12 text-[#5CD2C6]" />,
        text: "Book your first lesson"
    },
    {
        icon: <img src='/images/icons/laptop1.svg' alt='laptop' className="w-12 h-12 text-[#5CD2C6]" />,
        text: "Subscribe and learn independently"
    }
]

const TutorBrowserPage = () => {

    const [language, setLanguage] = useState(LANGUAGE_OPTIONS?.[0]?.value || "");
    const [pricePerLesson, setPricePerLesson] = useState(PRICE_PER_LESSON_OPTIONS?.[0]?.value || "");
    const [area, setArea] = useState(AREA_OPTIONS?.[0]?.value || "");
    const [showAppointmentModal, setShowAppointmentModal] = useState(false)
    const [tutorName, setTurorName] = useState("")
    const [step, setStep] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(null);

    const LABELS = [
        "I want to learn",
        "Price per lesson",
        "Area"
    ]


    function bookAppointment(tutor) {
        setTurorName(tutor)
        setShowAppointmentModal(true)
    }
    function onModalCLose() {
        setShowAppointmentModal(false)
        setStep(1)

        setSelectedTime(new Date())
        setSelectedDate(null)
    }


    return (<>
        <div className="flex flex-col gap-4">
            <HeroContainer className=" min-h-[300px] flex items-center justify-center px-4 pb-8">
                <div className="max-w-2xl w-full text-center space-y-6">
                    {/* Heading Section */}
                    <div className="space-y-3">
                        <h1 className="hero-title"
                        >
                            Find best quality tutors
                            <br />
                            for private lessons
                        </h1>
                        <p className="text-[#678285] text-center fredoka text-[19px] md:text-[22px]  font-medium leading-[32px] tracking-[-0.22px]"
                        >
                            Looking for an online tutor? We have the leading tutors
                        </p>
                    </div>

                    {/* Search Section */}
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col w-full md:flex-row justify-center gap-2 max-w-md mx-auto">
                            <div className="flex items-center w-full bg-white relative py-2 px-4 rounded-[16px]">
                                <select className="text-[#678285] w-full appearance-none outline-none font-[Fredoka] text-[16px] font-medium leading-[32px] tracking-[-0.16px] " name="" id="">
                                    <option value="spanish">Spanish</option>
                                    <option value="english">English</option>
                                    <option value="science">Science</option>
                                    <option value="maths">Maths</option>
                                </select>
                                <ExpandMoreIcon />
                            </div>
                            <OrangeButton label={"Search"} />
                        </div>
                        <Link to="/tutors" className="text-[#FFF] text-center font-[Fredoka] text-[18px] font-medium leading-[32px] tracking-[-0.18px] underline decoration-solid  decoration-[#FFF] underline-offset-auto text-underline-position-[from-font]"
                        >
                            Browse all tutors
                        </Link>
                    </div>
                </div>
            </HeroContainer>
            <CustomModal isOpen={showAppointmentModal} onClose={onModalCLose}>
                <BookingModal
                    setStep={setStep}
                    step={step}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    tutorname={tutorName}
                    setShowAppointmentModal={setShowAppointmentModal} />
            </CustomModal>
            <div className='p-4'>
                <Promo />
            </div>
            <main className='bg-white container rounded-[24px] !py-6 space-y-4'>
                <h2 className="text-teal-900 text-2xl leading-[1.5] font-semibold text-center">Browse Expert Tutors</h2>
                <div className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
                    <CustomSelect
                        className='w-full'
                        options={LANGUAGE_OPTIONS}
                        onSelect={(value) => setLanguage(value)}
                    >
                        <SelectWithLabelOption label={LABELS[0]} option={language} />
                    </CustomSelect>
                    <CustomSelect
                        className='w-full'
                        options={PRICE_PER_LESSON_OPTIONS}
                        onSelect={(value) => setPricePerLesson(value)}
                    >
                        <SelectWithLabelOption label={LABELS[1]} option={pricePerLesson} />
                    </CustomSelect>

                    <CustomSelect
                        className='w-full'
                        options={AREA_OPTIONS}
                        onSelect={(value) => setArea(value)}
                    >
                        <SelectWithLabelOption label={LABELS[2]} option={area} />
                    </CustomSelect>

                    <SearchTutorInput className='w-full' />
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mx-auto content-center'>
                    {Tutors?.map((tutor, index) => {
                        return (
                            <TutorCard
                                key={index}
                                data={tutor}
                                bookAppointment={bookAppointment}
                            />
                        )
                    })}
                </div>

            </main>
            <div className="w-full flex flex-col items-center justify-center bg-[#85D7D6] rounded-2xl  !space-y-16 py-8 md:py-12">
                <h2 className="text-[#012E33] container text-center font-bold fredoka text-[40px] leading-normal tracking-[-0.8px]"
                >
                    How Tutoring <br /> Works
                </h2>
                <div className="!mt-6 container grid md:grid-cols-3 md:gap-0 gap-12 grid-cols-1 w-full">
                    {steps.map((step, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="w-32 h-32 rounded-full bg-white flex items-center justify-center mb-4">
                                {step.icon}
                            </div>
                            <p className="text-[#012E33] text-center font-fredoka text-[22px] font-medium leading-normal tracking-[-0.44px] text-wrap"
                            >
                                {step.text}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <Footer />
    </>
    );
};

export default TutorBrowserPage;
