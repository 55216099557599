import HeroContainer from '../ui/hero-container'
import { Footer } from '../Footer'
import OrangeButton from '../buttons/orange-button'


export default function Waitlist() {

    return (<>
        <HeroContainer className="min-h-screen relative mb-5">
            <div className="mx-auto container !pt-2 text-center">
                <div className="inline-flex items-center gap-2 text-white px-4 py-2 mb-2">
                    <span className="text-[22px] fredoka"><i>We’re launching soon 🚀 </i></span>
                </div>

                <h1 className="text-[#FFF] text-center fredoka text-[40px] md:text-[56px] font-semibold leading-normal tracking-[-1.12px] mb-2"
                >
                    Join the Waitlist and get a discount
                </h1>

                <p className="text-[#678285] max-w-[550px] mx-auto text-center fredoka text-[19px] md:text-[22px] font-medium leading-[32px] tracking-[-0.22px] mb-3"
                >
                    Be a part of our early journey, help us build the product
                    you need to excel in your academics.
                </p>

                {/* CTA Button */}
                <div className="!mt-8 relative ">
                    <div className="flex flex-col lg:flex-row max-w-lg mx-auto gap-3">
                        <input type="text" placeholder={"Your Email Address"} className='rounded-2xl p-[10px] lg:w-[70%] text-[#678285] fredoka text-[16px] font-normal leading-normal tracking-[-0.16px]' />
                        {/* <InputField type="text" placeholder={"Your Email Address"} /> */}
                        <OrangeButton label={"Sign Up"} className='lg:!px-10 ' />
                    </div>
                    <img className='absolute hidden lg:inline  lg:right-2 xl:right-14 -top-28 w-[220px] h-[160px]' alt='nocreditcard' src='/images/hurry_up.png' />
                    <img className='lg:hidden mx-auto mt-4 lg:right-2 xl:right-14 max-w-[300px] -top-28 h-[160px]' alt='nocreditcard' src='/images/hurry_up2.png' />
                </div>
            </div>
            <div className='px-4 pb-4 md:mt-0 mt-12'>
                <img src='/images/waitlist-hero.png' alt='hero-img' className='md:block hidden' />
                <img src='/images/waitlist-hero-mobile.png' alt='hero-img' className='block w-full md:mt-0 md:hidden' />
            </div>
        </HeroContainer>

        <Footer />
    </>
    )
}

