
export const LANGUAGE_OPTIONS = [
    {
        value: "spanish",
    },
    {
        value: "english",
    },
    {
        value: "hindi",
    }
]

export const PRICE_PER_LESSON_OPTIONS = [
    {
        value: "$20-100",
    },
    {
        value: "$80-120",
    },
    {
        value: "$100-150",
    }
]

export const AREA_OPTIONS = [
    {
        value: "Houston, Texas",
    },
    {
        value: "Austin, Texas",
    },
    {
        value: "Dallas, Texas",
    }
]

export const BOOKING_TIMES = [
    ['10 AM - 11 AM', '11 AM - 12 PM',],
    ['2 PM - 3 PM', '3 PM - 4 PM', '6 PM - 7 PM',],
    ['7 PM - 8 PM', '8 PM - 9 PM', '9 PM - 10 PM',],
    ['10 PM - 11 PM'],
]