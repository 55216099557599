import React, { useState } from 'react';
import { REVIEWS_DATA } from '../constants/Reviews';
import StarRating from './StarRating';
import CustomProgressBar from '../../../Components/CustomProgressBar';

const RatingSummary = ({ ratings }) => {
    const totalRatings = ratings.reduce((acc, curr) => acc + curr, 0);

    return (
        <div className="flex flex-col md:flex-row items-start gap-8 md:gap-[77px] pb-8 px-1">
            <div>
                <div className="text-[#012E33] text-[40px] leading-normal font-normal -tracking-[0.8px] pb-2">4/5</div>
                <StarRating rating={4} />
                <div className="text-[#678285] text-base leading-normal font-normal -tracking-[0.32px] pt-4">Based on {totalRatings} Ratings</div>
            </div>
            <div className="flex-1 flex flex-col gap-1 w-full">
                {[5, 4, 3, 2, 1].map((rating) => (
                    <div key={rating} className="grid grid-cols-[auto_4fr_auto] md:grid-cols-[auto_1fr_auto] items-center gap-1.5">
                        <span className="text-[#bec8c9] text-base leading-normal font-normal -tracking-[0.32px]">{rating}</span>
                        <CustomProgressBar value={(ratings[5 - rating] / totalRatings) * 100} color='#85D7D6' trackColor='#EFF7F7' height={7} radius={0} />
                        <span className="text-[#678285] w-11 text-center text-base leading-normal font-normal -tracking-[0.32px]">({ratings[5 - rating]})</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ReviewCard = ({ review }) => {
    return (
        <div className="bg-[#EFF7F7] p-6 rounded-3xl">
            <StarRating rating={review.rating} />
            <p className="text-[#678285] text-base leading-[24px] font-normal -tracking-[0.32px] pt-4 pb-10">{review.comment}</p>
            <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-[#85D7D6] flex items-center justify-center text-white uppercase">
                    {review.name.charAt(0)}
                </div>
                <div>
                    <div className="text-[#012E33] text-base leading-[24px] font-normal -tracking-[0.32px]">{review.name}</div>
                    <div className="text-[#678285] text-xs leading-[24px] font-normal -tracking-[0.24px]">{review.date}</div>
                </div>
            </div>
        </div>
    );
};

const Reviews = () => {
    const [showAll, setShowAll] = useState(false);

    const ratings = [52, 24, 0, 0, 0];

    const displayedReviews = showAll ? REVIEWS_DATA : REVIEWS_DATA.slice(0, 6);

    return (
        <div className="flex flex-col gap-4">
            <RatingSummary ratings={ratings} />

            <div className="grid md:grid-cols-2 gap-4">
                {displayedReviews.map((review, index) => (
                    <ReviewCard key={index} review={review} />
                ))}
            </div>

            {REVIEWS_DATA.length > 6 && (
                <div className="text-center mt-6">
                    <button
                        className="px-6 py-2 rounded-full border border-[#66CDCC] w-fit mx-auto"
                        onClick={() => setShowAll(!showAll)}
                    >
                        <span className='text-[#66CDCC] text-base font-semibold leading-normal'>{showAll ? 'Show Less' : 'See More...'}</span>
                    </button>
                </div>
            )}


        </div>
    );
};

export default Reviews;