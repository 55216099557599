import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileSidebar from "./sidebar";
import EducationServicesCard from "./nav-components/education-service-card/EducationServicesCard";

export const Header = () => {
  const [openSolutionCard, setOpenSolutionCard] = useState(false)
  return (
    <header className="header sticky top-0 w-full z-20  ">
      <div className="container !w-full navbar-cont">
        <Link to={"/"} className="logo nav-logo-title !text-[#012E33]">
          <img src="/images/grad-img.svg" alt="grad" />
          IAMAI
        </Link>
        <nav
          className={`nav-links`}
        >
          <Link className="" to="/tutors">Tutors</Link>
          <a onClick={() => setOpenSolutionCard(!openSolutionCard)} className="nav-solution flex items-center gap-3" href="#solutions">Solutions <span> <img alt="arrow-down" src="/images/icons/arrow-down.svg" /> </span> </a>
          <EducationServicesCard setOpenSolutionCard={setOpenSolutionCard} openSolutionCard={openSolutionCard} />
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </nav>
        <div className="nav-button">
          <button className="login-btn">Login</button>
          <MobileSidebar />
        </div>
      </div>
    </header>
  );
};
