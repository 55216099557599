import React, { useState } from 'react'
import './sidebarStyle.css'
import { useNavigate } from 'react-router-dom';
const MobileSidebar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSolutionsOpen, setSolutionsOpen] = useState(false);
  const navigate = useNavigate()
  const toggleMenu = () => {
    setSolutionsOpen(false)
    setMenuOpen(!isMenuOpen);
  };
  const toggleSolutions = () => {
    setSolutionsOpen(!isSolutionsOpen);
  };

  const solutionsItems = [
    {
      icon: "/images/icons/GraduationCap.svg",
      title: "Academic Class Spaces",
      subtitle: "Personalised Learning Tool"
    },
    {
      icon: "/images/icons/BookOpen.svg",
      title: "College Counselling",
      subtitle: "Find Universities that match"
    },
    {
      icon: "/images/icons/TestTube.svg",
      title: "Standardized Testing",
      subtitle: "Prepare for ACT / SAT"
    }
  ];

  return (
    <div className='mobile-sidebar'>
      <div
        className="hamburger"
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`mobile-nav ${isMenuOpen ? "mobile-active" : ""} `}>
        <div className='menu-container'>
          <div onClick={() => { navigate("/"); setMenuOpen(false) }} className="nav-item">
            <div className="icon-wrapper">
              <img src="/images/icons/House.svg" alt="" style={{ height: "37px" }} />
            </div>
            <span className="nav-text">Home</span>
          </div>

          {/* <div className="nav-item">
                    <div className="icon-wrapper">
                    <img src="/images/icons/Laptop.svg" alt="" style={{height:"25px"}}/>
                    </div>
                    <span className="nav-text">Solutions</span>
                     <ChevronDown className="dropdown-arrow" />
                </div> */}

          <div className={` solutions ${isSolutionsOpen ? 'active' : ''}`}>
            <div className="nav-item" onClick={toggleSolutions}>
              <div className="icon-wrapper">
                <img src="/images/icons/Laptop.svg" alt="" style={{ height: "25px" }} />
              </div>
              <span className="nav-text">Solutions</span>
              <svg
                className="dropdown-arrow"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="solutions-content">
              {solutionsItems.map((item, index) => (
                <div key={index} className="solution-item">
                  <div className="solution-icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <div className="solution-text">
                    <div className="solution-title">{item.title}</div>
                    <div className="solution-subtitle">{item.subtitle}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div onClick={() => { navigate("/about"); setMenuOpen(false) }} className="nav-item">
            <div className="icon-wrapper">
              <img src="/images/icons/building.svg" alt="" style={{ height: "29px" }} />
            </div>
            <span className="nav-text">About</span>
          </div>

          <div className="nav-item">
            <div className="icon-wrapper">
              <img src="/images/icons/telephone.svg" alt="" style={{ height: "26    px" }} />
            </div>
            <span className="nav-text">Contact</span>
          </div>
        </div>
        <div className="button-container-sidebar">
          <button className="contact-button">Contact Us</button>
          <button className="trial-button">Start Free Trial</button>
        </div>
      </nav>
    </div>
  )
}

export default MobileSidebar