import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Partnerships from './Pages/Partnerships';
import Careers from './Pages/Careers';
import ComingSoon from './Pages/ComingSoon';
import NotFound from './Pages/NotFound';
import AcademicCourses from './Pages/AcademicCourses';
import CollegeCounseling from './Pages/CollegeCounseling';
import StandardizedTesting from './Pages/StandardizedTesting';
import TutorBrowserPage from './Pages/tutor/index.jsx';
import TutorDetails from './Pages/tutor-details/index.jsx';
import { Header } from './Components/Header.js';
import Policy from './Components/policy/policy.jsx';
import Terms from './Components/terms/terms.jsx';
import Waitlist from './Components/waitlist/waitlist.jsx';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/tutors" element={<TutorBrowserPage />} />
        <Route path="/tutor-details" element={<TutorDetails />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/tutor-details" element={<TutorDetails />} />

        {/* <Route path="/terms-and-condition" element={<Terms />} /> */}

        <Route path="/pricing" element={<ComingSoon />} />
        <Route path="/tos" element={<ComingSoon />} />
        <Route path="/privacy-policy" element={<ComingSoon />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/products/academic-courses" element={<AcademicCourses />} />
        <Route path="/products/college-counseling" element={<CollegeCounseling />} />
        <Route path="/products/standardized-testing" element={<StandardizedTesting />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
