import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileDetail from './components/ProfileDetail';
import PageNavigation from './components/PageNavigation';
import Footer from './components/Footer';
import Resume from './components/Resume';
import Experience from './components/Experience';
import Certifications from './components/Certifications';
import Availability from './components/Availability';
import Reviews from './components/Reviews';
import ShadowBtn from '../../Components/ShadowBtn';
import CustomModal from '../../Components/CustomModal';

const TutorDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [openVideoModal, setOpenVideoModal] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (!location.state) {
            navigate('/tutors');
        }
    }, [location.state, navigate]);

    const tutorDetails = location.state?.tutorDetails;

    const handleCloseModal = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
        setOpenVideoModal(false);
    };

    if (!tutorDetails) {
        return null;
    }

    return (
        <div className="flex flex-col bg-[#EFF7F7]">
            <div className="relative bg-white pb-[72px]">
                <div className="transition-all relative max-w-[1054px] w-full mx-auto flex flex-col md:flex-row items-start justify-center gap-[35px] top-0 md:pt-[68px] pb-[72px] z-[1] px-2">
                    <div className="w-full md:w-[305px] lg:w-[405px] flex md:sticky top-[68px] z-[1] flex-col gap-4">
                        <div
                            className="relative w-full h-[405px] cursor-pointer"
                            onClick={() => setOpenVideoModal(true)}
                        >
                            <img
                                src={tutorDetails?.imageUrl || "/tutor/tutor4.png"}
                                alt="tutor"
                                className="w-full h-full object-cover rounded-3xl aspect-square"
                            />
                            <img
                                src="/images/icons/play.svg"
                                alt="play"
                                className="w-12 h-12 object-contain absolute bottom-4 right-4 rounded-full shadow-[0px_2.4px_7.2px_rgba(0,0,0,0.45)]"
                            />
                        </div>
                        <ShadowBtn className="w-full" text="Book Now" />
                        <button
                            className="py-2 px-6 rounded-2xl border border-[#66CDCC] text-[#66CDCC] text-base flex items-center justify-center gap-2 w-full"
                        >
                            <img src="/images/icons/heart.svg" alt="" className="w-6 h-6 object-contain" />
                            Save to my list
                        </button>
                    </div>

                    {/* Main Profile Details */}
                    <div className="w-full md:w-[calc(100%-440px)] flex flex-col gap-6 pt-6">
                        <div className="relative w-full bg-[#EFF7F7] rounded-b-3xl pt-[68px] md:px-12">
                            <ProfileDetail tutorDetails={tutorDetails} />
                            <PageNavigation className="mt-auto" />
                        </div>

                        {/* Resume Section */}
                        <div className="flex flex-col gap-6 px-3" id="resume">
                            <p className="font-normal text-[22px] leading-normal tracking-[-0.44px] text-[#012E33]">Resume</p>
                            <div className="pb-10">
                                <Resume />
                            </div>
                        </div>
                        <hr className="border-[#D9DFDF]" />

                        {/* Experience Section */}
                        <div className="flex flex-col gap-6 pt-6 px-3" id="experience">
                            <p className="font-normal text-[22px] leading-normal tracking-[-0.44px] text-[#012E33]">Experience</p>
                            <div className="pb-10">
                                <Experience />
                            </div>
                        </div>
                        <hr className="border-[#D9DFDF]" />

                        {/* Certifications Section */}
                        <div className="flex flex-col gap-6 pt-6 px-3" id="certifications">
                            <p className="font-normal text-[22px] leading-normal tracking-[-0.44px] text-[#012E33]">Certifications</p>
                            <div className="pb-6">
                                <Certifications />
                            </div>
                        </div>
                        <hr className="border-[#D9DFDF]" />

                        {/* Availability Section */}
                        <div className="flex flex-col gap-6 pt-6 px-3" id="availability">
                            <p className="font-normal text-[22px] leading-normal tracking-[-0.44px] text-[#012E33]">Availability</p>
                            <div className="pb-6">
                                <Availability />
                            </div>
                        </div>
                        <hr className="border-[#D9DFDF]" />

                        {/* Reviews Section */}
                        <div className="flex flex-col gap-6 pt-6 px-3" id="reviews">
                            <p className="font-normal text-[22px] leading-normal tracking-[-0.44px] text-[#012E33]">Reviews</p>
                            <Reviews />
                        </div>
                    </div>

                </div>
                <Footer />
            </div>

            {/* Modal for Video */}
            <CustomModal isOpen={openVideoModal} onClose={handleCloseModal}>
                <video
                    ref={videoRef}
                    src={tutorDetails?.introduction_video || "/videos/tutor-intro.mp4"}
                    controls
                    muted
                    className="w-full h-auto"
                ></video>
            </CustomModal>
        </div>
    );
};

export default TutorDetails;
