import React from 'react'

const Promo = () => {
    return (
        <div className="bg-teal-400 text-white container !py-6 rounded-lg">
            <div className='w-fit max-w-[630px] space-y-4'>
                <h2 className="text-2xl leading-[1.5] font-semibold">🤩 Claim your (1) free tutoring session with our experts</h2>
                <p className="text-lg font-normal leading-[1.25]">
                    Your 1st tutoring session is on us. See how our experts can add value to your learning journey. Over 95% students see better results after booking a tutor.
                </p>
            </div>
        </div>
    )
}

export default Promo