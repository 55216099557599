'use client'

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useState } from 'react'

const testimonials = [
    {
        id: 1,
        name: 'Kareena H.',
        role: 'English Tutor',
        image: '/images/contact-test.png',
        quote: 'IAMAI team has connected me with over 100+ students and have also guided me to improve my tutoring practice.',
    },
    {
        id: 2,
        name: 'Nicholas D.',
        role: 'Parent',
        image: '/images/contact-man.png',
        quote: 'IAMAI is an amazing platform and has helped me improve my performance. I love IAMAI and you would too.',
    },
    {
        id: 3,
        name: 'Sarah M.',
        role: 'Math Tutor',
        image: '/images/contact-test.png',
        quote: 'The support and resources provided by IAMAI have been invaluable to my teaching journey.',
    },
]

export default function ContactTestimonialCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0)

    const next = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        )
    }

    const previous = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        )
    }

    return (
        <div className="w-full  !mx-auto lg:!mt-20 lg:!px-4 lg:!py-12">
            <h2 className="text-center text-3xl mt-2 lg:text-4xl font-bold  lg:mb-12 text-[#1a1a1a]">
                <span className="text-center text-[#012E33] font-[Fredoka]  font-semibold leading-normal tracking-[-0.8px]">Trusted by parents <br /> and loved by students </span>{' '}
                <span className="block fredoka lg:inline"></span>
            </h2>
            <div className="relative">
                <div className="">
                    <div>
                        <div className="relative flex mx-auto flex-col lg:flex-row lg:items-center lg:gap-8 p-4 lg:p-8 max-w-[900px] rounded-2xl">
                            <div className="w-[90%] sm:w-[60%] mx-auto lg:m-0 lg:w-[400px]">
                                <img
                                    src={testimonials[currentIndex].image}
                                    alt=""
                                    className="w-full aspect-square object-cover rounded-xl"
                                />
                            </div>

                            <div className="w-[90%] sm:w-[60%] lg:w-2/3  -mt-12 max-w-lg rounded-[24px] bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.08)] p-4 lg:absolute lg:top-16 lg:-right-4 lg:mt-0 mx-auto">
                                <blockquote className="text-[#012E33] font-[Fredoka] text-[22px] lg:text-[32px] font-medium leading-normal tracking-[-0.64px]">
                                    {testimonials[currentIndex].quote}
                                </blockquote>
                                <div>
                                    <p className="text-[#012E33] font-[Fredoka] text-[22px] font-normal leading-[32px] tracking-[-0.22px]"
                                    >
                                        {testimonials[currentIndex].name}
                                    </p>
                                    <p className="text-[#678285] font-[Fredoka] text-[18px] font-normal leading-normal tracking-[-0.18px]"
                                    >
                                        {testimonials[currentIndex].role}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start gap-4 mx-auto mt-6 lg:-mt-2 lg:mx-0 lg:self-end">
                                <button
                                    onClick={previous}
                                    className="p-2 rounded-full border border-gray-300 hover:bg-gray-100 transition-colors"
                                    aria-label="Previous testimonial"
                                >
                                    <ChevronLeft className="w-5 h-5" />
                                </button>
                                <button
                                    onClick={next}
                                    className="p-2 rounded-full border border-gray-300 hover:bg-gray-100 transition-colors"
                                    aria-label="Next testimonial"
                                >
                                    <ChevronRight className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

