import React from 'react';
import { useNavigate } from 'react-router-dom';
import OrangeButton from '../Components/buttons/orange-button';
import OrangeButtonOutline from '../Components/buttons/orange-button-outline';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col justify-center items-center gap-2 bg-[#012E33] h-[calc(100vh-80px)]'>
      <h1 className="text-white text-center font-[Fredoka] text-[120px] font-bold leading-normal tracking-[-2.4px]"
      >404</h1>
      <p className="text-white text-center font-[Fredoka] text-[22px] font-medium leading-[32px] tracking-[-0.22px]"
      >Something’s wrong here...</p>
      <p className="text-[#678285] text-center font-[Fredoka] text-[22px] font-normal leading-[32px] tracking-[-0.22px]"
      >We could not find the page you’re looking for.</p>
      <div className='flex items-center gap-4'>
        <OrangeButton onClick={handleGoHome} label={"Go Back Home"} />
        <OrangeButtonOutline label={"Contact Us"} />
      </div>
    </div>
  );
};

export default NotFound;
