import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Footer } from '../Components/Footer';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenRoles from '../Components/about-page/open-roles';
import OrangeButton from '../Components/buttons/orange-button';
import { styled } from '@mui/material/styles';
import FreeTrial from '../Components/about-page/free-trial';
import AboutHeroSection from '../Components/about-page/hero-section';
import ValuesSlider from '../Components/about-page/our-values';
const About = () => {
    const StyledSection = styled('section')({
        backgroundColor: '#e6f7ff',
        padding: '60px 0',
        textAlign: 'center',
        width: "100%"
    });

    const TeamCard = styled(Card)({
        maxWidth: 200,
        margin: '0 auto',
        backgroundColor: 'transparent',
        padding: "0",
        boxShadow: 'none',
    });

    const TeamImage = styled('img')({
        width: '100%',
        height: 200,
        objectFit: 'cover',
        borderRadius: '8px',
    });

    const TeamMemberName = styled(Typography)({
        fontSize: '16px',
        fontWeight: 600,
        marginTop: '16px',
        marginBottom: '4px',
    });

    const TeamMemberRole = styled(Typography)({
        fontSize: '14px',
        color: '#666',
    });


    const publicUrl = process.env.PUBLIC_URL;

    const cardData = [
        {
            title: "Support Scholarships for Students from Lower Socio-Economic Families",
            description:
                "A $500-$650 donation provides a semester-long access to our AI College Counselor product and 2-3 in-person or online tutoring sessions for academic subjects or standardized test prep. Our first round of scholarships will be awarded in January 2025.",
            imageUrl: "/images/about/about-card-img-1.png",
        },
        {
            title: "Bringing Modern Education Technology to Rural Communities",
            description:
                "We plan to visit rural towns in Texas this year. Donors who contribute $100-$300 can select a town and school for us to visit, helping us bring advanced educational tools to underserved areas.",
            imageUrl: "/images/about/about-card-img-2.png",
        },
    ];

    const faqData = [
        { question: "What does IAMAI Mean?", answer: "We have over 100 subjects on IAMAI, including languages, school and university subjects, and art." },
        { question: "What do we believe in?", answer: "We believe in empowering students and educators by leveraging AI for better learning experiences." },
        { question: "How are we changing the education space?", answer: "We aim to make education accessible, personalized, and tech-driven for everyone." },
        { question: "How can AI improve technology?", answer: "AI can streamline learning processes, enhance teaching methods, and provide customized support for students." },
    ];

    const teamMembers = [
        {
            name: 'John Mason Mings',
            role: 'CEO',
            image: '/images/john.png'
        },
        {
            name: 'Noah Perelmuter',
            role: 'Chief Intern',
            image: '/images/noah.png'
        }
    ];

    return (
        <div className="About">
            <AboutHeroSection />
            {/* Mission Section */}
            <div className="mission-section container">
                <div className="our-mission-box">
                    <h3 className="mission-title fredoka">🎯 Our Mission</h3>
                    <h1 className="mission-sub fredoka">To provide personalized learning experiences for students worldwide, helping them reach their full potential.</h1>
                </div>
            </div>

            {/* Initiatives Section */}
            <div className="container our-initiatives">
                <h2 className="initiative-title">Our Initiatives</h2>
                <div className="card-section">
                    {cardData.map((card, index) => (
                        <div className="card" key={index}>
                            <img className="card-image" src={card.imageUrl} alt={card.title} />
                            <div className="card-content">
                                <h3 className="card-title fredoka">{card.title}</h3>
                                <p className="card-description fredoka">{card.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="initiative-btn-container" style={{ margin: '20px 0' }}>
                    <OrangeButton label="Support our Initiatives" />
                </div>
            </div>

            {/* Story and FAQ Section */}
            <Box className="container">
                <Grid container spacing={4} alignItems="top">
                    <Grid item xs={12} md={6}>
                        <Typography fontFamily="fredoka" variant="h6" sx={{ color: '#003b47', fontWeight: 'bold', marginBottom: '10px' }}>
                            🚀 Our Story
                        </Typography>
                        <Typography
                            fontFamily="fredoka"
                            variant="h4"
                            sx={{ fontWeight: '600', marginBottom: '20px', lineHeight: 1.4, color: '#003b47', fontSize: { xs: '40px', sm: 'inherit' } }}
                        >
                            In 2023, a group of educators and technologists united to tackle the challenges in education.
                        </Typography>
                        <Typography fontFamily="fredoka" variant="body1" sx={{ marginBottom: '20px', color: '#4a4a4a', fontSize: { xs: '22px', sm: 'inherit' } }}>
                            Recognizing AI's transformative potential, they founded IAMAI with a mission to create personalized learning experiences, using technology to empower students to reach their full potential.
                        </Typography>

                        {/* FAQ Accordion */}
                        <Box sx={{ marginTop: '40px' }}>
                            {faqData.map((faq, index) => (
                                <Accordion key={index} sx={{ backgroundColor: '', boxShadow: 'none' }}>
                                    <AccordionSummary fontFamily="fredoka" expandIcon={<ExpandMoreIcon />} sx={{ fontWeight: 'normal', color: '#003b47' }}>
                                        {faq.question}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontFamily="fredoka" fontWeight="400" variant="body2" sx={{ color: '#4a4a4a' }}>
                                            {faq.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Grid>

                    {/* Image Section */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <img src="/images/about/faq-img-1.png" alt="Educators" style={{ width: '100%', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <img src="/images/about/faq-img-2.png" alt="Teaching" style={{ width: '100%', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <img src="/images/about/faq-img-3.png" alt="Classroom" style={{ width: '100%', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <img src="/images/about/faq-img-4.png" alt="Discussion" style={{ width: '100%', borderRadius: '8px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* Values Section */}
            <ValuesSlider />

            {/* Team Section */}
            <StyledSection>
                <Container maxWidth="md">
                    <Box mb={6}>
                        <h6 className="text-[#012E33] text-center  fredoka text-[22px] font-medium leading-[32px] tracking-[-0.22px]">
                            <span role="img" aria-label="handshake">😎</span> Our Team
                        </h6>
                        <h3 className="text-[#012E33] text-center fredoka text-[40px] font-semibold leading-normal tracking-[-0.8px]"
                        >
                            Diverse team with a <br />
                            single mission
                        </h3>
                        <Typography variant="h6" sx={{ color: "#678285", mb: 3, fontStyle: "normal" }}>
                            We have proven that a strong sense of purpose can cross <br /> borders, languages and time zones.
                        </Typography>
                    </Box>

                    <Grid container spacing={4} justifyContent="center">
                        {teamMembers.map((member, index) => (
                            <Grid item key={index}>
                                <TeamCard>
                                    <TeamImage src={publicUrl + member.image} alt={member.name} />
                                    <CardContent className='team-member-cont'>
                                        <TeamMemberName className='team-member-name'>{member.name}</TeamMemberName>
                                        <TeamMemberRole className='team-member-role'>{member.role}</TeamMemberRole>
                                    </CardContent>
                                </TeamCard>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </StyledSection>

            {/* Open Roles Section */}
            <OpenRoles />


            {/* Free Trial Section */}
            <div className="container">
                <FreeTrial />
            </div>

            {/* Footer */}
            <Footer />
        </div>

    );
}

export default About;
