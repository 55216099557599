import React, { useEffect } from "react";

const CustomModal = ({ isOpen, onClose, children, className = "" }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [isOpen]);
    
    return (
        <div
            className={`fixed bg-black/55 z-50 inset-0 flex items-center justify-center transition-opacity duration-300 ease-in-out ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                }`}
            onClick={onClose}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={`relative w-fit max-w-[680px] bg-white rounded-2xl shadow transform transition-transform duration-300 ${isOpen ? "scale-100" : "scale-90"
                    } ${className ? className : ""}`}
            >
                <button
                    type="button"
                    onClick={onClose}
                    className="absolute top-[21px] z-50 right-4 bg-transparent"
                >
                    <img src="/images/icons/cancel.svg" alt="close" className="w-4 h-4 object-contain" />
                </button>

                <div className="space-y-4">{children}</div>
            </div>
        </div>
    );
};

export default CustomModal;
