import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const navigation = {
  solutions: [
    { name: 'Academic Class Spaces', href: '#' },
    { name: 'College Counseling', href: '#' },
    { name: 'Standardized Testing', href: '#' },
  ],
  tutors: [
    { name: 'Browse Tutors', href: '/tutors' },
    { name: 'Spanish Tutors', href: '#' },
    { name: 'Math Tutors', href: '#' },
    { name: 'History Tutors', href: '#' },
    { name: 'English Tutors', href: '#' },
    { name: 'Apply as Tutor', href: '#' },
  ],
  company: [
    { name: 'About Us', href: '/about' },
    { name: 'Advisory Board', href: '#' },
    { name: 'Careers', href: '#' },
    { name: 'Partnerships', href: '#' },
    { name: 'Privacy Policy', href: '#' },
    { name: 'Terms of Services', href: '#' },
  ],
  support: [
    { name: 'Contact Us', href: '/contact' },
    { name: 'Email Us', href: '#' },
    { name: 'Request a Feature', href: '#' },
  ],
};

const socials = [
  { name: 'LinkedIn', icon: '/social-media/LinkedinLogo.svg', href: '#' },
  { name: 'Facebook', icon: '/social-media/FacebookLogo.svg', href: '#' },
  { name: 'Twitter', icon: '/social-media/XLogo.svg', href: '#' },
  { name: 'Instagram', icon: '/social-media/InstagramLogo.svg', href: '#' },
];

export const Footer = () => {

  const [email, setEmail] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    // Handle newsletter subscription logic here
    console.log("Subscribing email:", email)
    setEmail("")
  }


  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-logo">
            <img src={"/images/logo.svg"} alt="IAMAI Logo" />
            <h2>I am always improving 🚀</h2>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-5 gap-10">
          {Object.keys(navigation).map((key) => (
            <FooterSection key={key} title={key} contents={navigation[key]} />
          ))}
          <div className="!w-full col-span-2 md:col-span-1 flex flex-col justify-center p-4 h-fit rounded-[24px] bg-gradient-to-r from-[#FFF] via-[#F7F7F7] to-[#F7F7F7]">
            <h2 className="text-[16px] text-left font-semibold text-gray-800 mb-6">
              Subscribe to our Newsletter
              <span className="block text-gray-600 text-[14px] font-normal mt-1">
                for our latest updates
              </span>
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email Address"
                className="w-full outline-none px-4 py-3 flex-shrink-0 !text-base fredoka text-black rounded-[16px] border border-[#DCDCDC] bg-[#FFF]"
                required
              />

              <button
                type="submit"
                className="flex w-full p-[8px_24px] justify-center items-center gap-[8px] flex-shrink-0 rounded-[16px] border border-[#66CDCC] text-[#66CDCC] fredoka text-[16px] leading-normal"

              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="footer-bottom">
          <p className="footer-copyright">&copy; All rights reserved 2024</p>
          <div className="footer-social">
            <span>Connect with us</span>
            <div className='contact-box'>
              {socials.map((social) => (
                <a key={social.name} href={social.href}>
                  <img src={social.icon} alt={social.name} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

function FooterSection({ title, contents }) {
  return (
    <div className="footer-section">
      <h3>{title}</h3>
      <ul>
        {contents.map((item) => (
          <li key={item.name}>
            <Link to={item.href}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
