import React from 'react'

const SearchTutorInput = ({ className = "", placeholder = "Search Tutors" }) => {
    return (
        <label className={`${className} px-4 py-3 rounded-lg flex justify-between items-center border border-gray-300 bg-white`}>
            <input
                type="text"
                className='w-full border-none outline-none bg-inherit font-medium -tracking-[0.16px] leading-normal text-[#678285]'
                placeholder={placeholder}
            />

            <img src="/icons/search.svg" alt="" />
        </label>
    )
}

export default SearchTutorInput