import React, { useEffect, useState } from 'react';
import OrangeButton from './buttons/orange-button';
import HeroContainer from './ui/hero-container';

export function HeroSection() {
  const texts = ["IAMAI - I am always Improving 🚀"];
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(100);

  useEffect(() => {
    const handleTyping = () => {
      const currentText = texts[textIndex];

      if (!isDeleting) {
        if (displayText.length < currentText.length) {
          setDisplayText(currentText.slice(0, displayText.length + 1));
          setTypingSpeed(100);
        } else {
          setTimeout(() => setIsDeleting(true), 2000);
        }
      } else {
        if (displayText.length > 0) {
          setDisplayText(currentText.slice(0, displayText.length - 1));
          setTypingSpeed(50);
        } else {
          setIsDeleting(false);
          setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, textIndex, typingSpeed]);
  return (
    <HeroContainer className="min-h-screen">
      <div className="mx-auto container !pt-2 text-center">
        <div className="inline-flex h-10 items-center gap-2 text-white px-4 py-2 rounded-full mb-6">
          <span className="text-sm fredoka">
            <i>{displayText}</i>
          </span>
        </div>

        <h1 className="hero-title relative"
        >
          Personalized Learning for<br />Your Child's Success
          <div className="absolute hidden lg:flex transform rotate-[-30deg] top-4 left-28 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            🫶
          </div>
          <div className="absolute hidden lg:flex transform rotate-[-10deg] top-36 left-20 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            📚
          </div>
          <div className="absolute hidden lg:flex transform rotate-[-10deg] top-48 left-40 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            🎓
          </div>
          <div className="absolute hidden lg:flex transform rotate-[30deg]  top-4 right-28 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            😍
          </div>
          <div className="absolute hidden lg:flex transform rotate-[10deg] top-36 right-20 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            📖
          </div>
          <div className="absolute hidden lg:flex transform rotate-[10deg] top-48 right-40 text-[40px] text-[#FFF] leading-normal tracking-[-1.12px]">
            😁
          </div>
        </h1>
        <p className="text-[#678285] max-w-2xl mx-auto text-center fredoka text-[19px] md:text-[22px] font-medium leading-[32px] tracking-[-0.22px]"
        >
          Empower your child with AI-driven tools that create personalized learning spaces. Our Class Spaces keep students organized, focused, and on track for success.
        </p>

        {/* CTA Button */}
        <div className="!mt-4 relative">
          <OrangeButton label={"Start Free Trial"} />
          <img className='absolute inset-0 m-auto top-32' alt='nocreditcard' src='/images/no-credit-card.png' />
        </div>
      </div>
      <div className='px-4 pb-4 md:mt-0 mt-12'>
        <img src='/images/home-hero.png' alt='hero-img' className='md:block hidden' />
        <img src='/images/hero-lady.png' alt='hero-img' className='block md:mt-0 md:hidden' />
      </div>
    </HeroContainer>
  );
};

export default HeroSection;
