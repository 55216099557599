import React, { useEffect, useRef, useState } from "react";
import "./OurValues.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";



const ValuesSlider = () => {
  const valuesData = [
    {
      title: "Accessibility",
      description: "Committed to breaking down socio-economic, linguistic, and geographical barriers to education.",
      icon: "/images/icons/Wheelchair.svg", // Replace with appropriate icons or images
      color: "#66CDCC"
    },
    {
      title: "Personalization",
      description: "Tailoring content and attention to meet each student’s unique needs.",
      icon: "/images/icons/PersonArmsSpread.svg",
      color: "#FEF991"
    },
    {
      title: "Curiosity",
      description: "Sparking a love for learning through encouragement of curiosity and exploration.",
      icon: "/images/icons/Brain.svg",
      color: "#FC823E"
    },
    {
      title: "Innovation",
      description: "Leveraging cutting-edge technology to provide advanced educational resources.",
      icon: "/images/icons/LightbulbFilament.svg",
      color: "#66CDCC"
    },
    {
      title: "Clarity",
      description: "Enhancing student organization so they always know their next steps.",
      icon: "/images/icons/Eyeglasses.svg",
      color: "#FEF991"
    },
    {
      title: "Efficiency",
      description: "Balancing required coursework with opportunities for students to explore additional interests.",
      icon: "/images/icons/PresentationChart.svg",
      color: "#FC823E"
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const sliderRef = useRef(null)

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? valuesData.length - 1 : prevIndex - 1
    )
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === valuesData.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) {
      handleNext()
    }

    if (touchStart - touchEnd < -75) {
      handlePrev()
    }
  }

  useEffect(() => {
    const slider = sliderRef.current
    if (slider) {
      const translateX = -currentIndex * 100
      slider.style.transform = `translateX(${translateX}%)`
    }
  }, [currentIndex])
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      handleNext();
    }, 1200);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="values-container">
      <div className="our-values-header">
        <div className="values-header">
          <h4 className="text-[#012E33] text-center fredoka text-[22px] font-medium leading-[32px] tracking-[-0.22px]"
          > 🙌 Our Values</h4>
          <h2 className="text-[#012E33] text-center font-[Fredoka] text-[40px] font-normal leading-normal tracking-[-0.8px]"
          >We believe that successful startups are built with empowered teams</h2>
          <p className="text-[#678285] text-center fredoka text-[22px] font-normal leading-[32px] tracking-[-0.22px]"
          >Our daily operations are built on six key values</p>
        </div>
      </div>
      <div className="values-grid-slider ">
        <div className="values-grid">
          {valuesData.map((value, index) => (
            <div key={index} className="value-card">
              <div
                className="value-icon"
                style={{ backgroundColor: value.color }}
              >
                <img src={value.icon} alt="" />
              </div>
              <div className="mb-auto">
                <h3 className="text-[#012E33] text-center fredoka text-[22px] font-medium leading-[32px] tracking-[-0.22px]"
                >{value.title}</h3>
                <p className="text-[#678285] text-center fredoka text-[18px] font-normal leading-[26px] tracking-[-0.36px]"
                >{value.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="values-slider-container">
          <button className="slider-button prev" onClick={handlePrev} aria-label="Previous value">
            <ChevronLeft />
          </button>
          <div
            className="values-slider"
            ref={sliderRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {valuesData.map((value, index) => (
              <div key={index} className="value-slide">
                <div className="value-card">
                  <div
                    className="value-icon"
                    style={{ backgroundColor: value.color }}
                  >
                    <img src={value.icon} alt="" />
                  </div>
                  <h3 className="fredoka">{value.title}</h3>
                  <p className="fredoka">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="slider-button next" onClick={handleNext} aria-label="Next value">
            <ChevronRight />
          </button>
        </div>
        <div className="slider-dots">
          {valuesData.map((_, index) => (
            <button
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
              aria-label={`Go to value ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ValuesSlider

