import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import './openRoles.css'
import OrangeButton from "../../buttons/orange-button";
import OrangeButtonOutline from "../../buttons/orange-button-outline";
const rolesData = [
    {
        title: "Test Automation Developer",
        description: "Engineering | Full time, Remote",
    },
    {
        title: "AI Engineer",
        description: "Engineering | Full time, Remote",
    },
    {
        title: "Marketing Manager",
        description: "Marketing | Full time, Remote",
    },
];

const OpenRoles = () => {
    return (
        <Box className="" sx={{ py: 6, px: 3, backgroundColor: "#f9fafc", textAlign: "center" }}>
            {/* Header Section */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ color: "#003B47", fontWeight: "bold", mb: 1 }}>
                    👋 Join us
                </Typography>
                <Typography variant="h4" sx={{ color: "#003B47", fontWeight: "bold", mb: 2 }}>
                    Open Roles
                </Typography>
                <Typography variant="body1" sx={{ color: "#4A4A4A", mb: 3 }}>
                    If you are interested in joining our advisory board, we would love to hear from you.
                </Typography>
                <div className="flex flex-col md:flex-row gap-4 mx-autoitems-center justify-center">
                    <OrangeButton
                        variant="contained" label={"Contact Us"}
                    />
                    <OrangeButtonOutline label={"See All Openings"} />
                </div>
            </Box>

            {/* Roles Section */}
            <Grid container spacing={3} justifyContent="center">
                {rolesData.map((role, index) => (
                    <Grid item xs={12} key={index}>
                        <Card
                            className="roles-center"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: { xs: "12px", sm: "18px" },  // Reduced padding for mobile
                                borderRadius: "32px",
                                maxWidth: "800px",
                                backgroundColor: "#E0F7FA",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "24px",
                                        width: { xs: "40px", sm: "50px", md: "65px", lg: "83px" },  // Smaller image size on mobile
                                        height: { xs: "40px", sm: "50px", md: "65px", lg: "83px" },
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img src="/images/grad-img.svg" alt="" style={{ width: "50%", height: "auto" }} />
                                </Box>
                                <Box sx={{ textAlign: "left" }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: "#012E33",
                                            fontWeight: "normal",
                                            fontSize: { xs: "14px", sm: "16px", md: "18px" },  // Smaller font size on mobile
                                        }}
                                    >
                                        {role.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "#678285",
                                            fontSize: { xs: "12px", sm: "14px" },  // Smaller font size on mobile
                                        }}
                                    >
                                        {role.description}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    borderRadius: "24px",
                                    border: "1px solid #044047",
                                    width: { xs: "40px", sm: "50px", md: "65px", lg: "83px" },  // Smaller icon size on mobile
                                    height: { xs: "40px", sm: "50px", md: "65px", lg: "83px" },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ArrowForwardIosIcon sx={{ color: "#003B47", fontSize: { xs: "16px", sm: "20px" } }} /> {/* Adjust icon size for mobile */}
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OpenRoles;
