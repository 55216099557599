import React from 'react'

const ShadowBtn = ({ className = "", text = "", onClick = () => { } }) => {
    return (
        <button
            className={`${className} bg-[#FC823E] w-[192px] text-white h-12 mx-auto rounded-2xl shadow-[0px_1px_2px_0px_rgba(255,255,255,0.40)inset,0px_4px_4px_0px_rgba(255,255,255,0.35)inset] px-6 py-2 flex justify-center items-center text-base font-semibold leading-none fredoka`}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default ShadowBtn