import React from 'react'

const CustomProgressBar = ({ trackColor = "#E5E7EB", color = "#014451", value = 25, height = 6, radius = 2 }) => {
    return (
        <div style={{ height: `${height}px`, borderRadius: `${radius}px`, backgroundColor: `${trackColor}` }} className="w-full">
            <div
                style={{ width: `${value}%`, height: `${height}px`, borderRadius: `${radius}px`, backgroundColor: `${color}` }}
            />
        </div>
    )
}

export default CustomProgressBar