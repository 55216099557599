
export const TIME_LINE_DATA = [
    {
        title: "Marketing Manager",
        period: "2022 - Present",
        descriptions: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum."
        ]
    },
    {
        title: "Marketing Manager",
        period: "2022 - Present",
        descriptions: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum."
        ]
    },
    {
        title: "Marketing Manager",
        period: "2022 - Present",
        descriptions: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor faucibus nisi ac dignissim. Aenean et orci quis metus gravida ultramcorper ut eget justo. Ut in sapien orci. Praesent in tempor sapien. Praesent id maximus tellus. Donec sed hendrerit augue. Fusce facilisis ex id facilisis fermentum."
        ]
    }
];