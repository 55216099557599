import React, { useState, useRef, useEffect } from 'react';

const PageNavigation = ({ className = "" }) => {
    const [activeTab, setActiveTab] = useState(0);

    const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });

    const containerRef = useRef(null);

    const tabsRef = useRef([]);
    const animationFrameRef = useRef(null);

    // Update indicator position
    const updateIndicator = () => {
        const selectedTab = tabsRef.current[activeTab];
        const container = containerRef.current;

        if (selectedTab && container) {
            const containerScrollLeft = container.scrollLeft;
            const { offsetLeft, offsetWidth } = selectedTab;
            setIndicatorStyle({
                left: offsetLeft - containerScrollLeft,
                width: offsetWidth,
            });
        }
    };

    // Optimized scroll handling
    const handleScroll = () => {
        if (animationFrameRef.current) return;
        animationFrameRef.current = requestAnimationFrame(() => {
            updateIndicator();
            animationFrameRef.current = null;
        });
    };

    // Listen to scroll events
    useEffect(() => {
        const container = containerRef.current;
        updateIndicator(); // Initial setup
        container?.addEventListener('scroll', handleScroll);

        return () => {
            container?.removeEventListener('scroll', handleScroll);
            if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current);
        };
    }, [activeTab]);

    const tabs = [
        { label: 'Resume' },
        { label: 'Experience' },
        { label: 'Certifications' },
        { label: 'Availability' },
        { label: 'Reviews' }
    ];

    useEffect(() => {
        const currentTab = tabsRef.current[activeTab];
        if (currentTab) {
            setIndicatorStyle({
                left: currentTab.offsetLeft,
                width: currentTab.offsetWidth
            });
        }
    }, [activeTab]);

    const handleScrollToSection = (id) => {
        const element = document.getElementById(id);
        const header = document.getElementById('header');
        const headerHeight = header ? header.offsetHeight : 0;

        if (element) {
            const yOffset = -headerHeight;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

  
    return (
        <div className={`${className} w-full relative px-3`}>
            {/* Tabs Container */}
            <div
                className="w-full flex justify-start overflow-x-auto scrollbar-hide relative gap-8"
                ref={containerRef}
            >
                {tabs.map((tab, idx) => (
                    <button
                        key={idx}
                        ref={(el) => (tabsRef.current[idx] = el)}
                        className={`pb-4 text-sm sm:text-base leading-none -tracking-[0.32px] transition-colors duration-300 ${
                            activeTab === idx
                                ? 'text-[#66CDCC] font-semibold'
                                : 'text-[#678285] font-normal hover:text-[#66CDCC]'
                        }`}
                        onClick={() => {
                            setActiveTab(idx)
                            handleScrollToSection(`${tab.label.toLowerCase()}`);
                        }}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Indicator */}
            <div
                className="absolute bottom-0 h-0.5 bg-[#66CDCC] transition-all duration-300 ease-in-out mx-3"
                style={{
                    left: indicatorStyle.left,
                    width: indicatorStyle.width,
                }}
            />
        </div>
    
    

    );
};

export default PageNavigation;