import React from 'react'

const SelectWithLabelOption = ({ label = "I want to learn", option = "Spanish" }) => {
    return (
        <div className='px-4 py-3 rounded-lg !h-full flex justify-between items-center border border-gray-300 bg-white'>
            <div className='flex flex-col gap-1'>
                <p className='text-[#012E33] text-sm -tracking-[0.28px]'>{label}</p>
                <p
                    className='text-[#012E33] text-base font-medium -tracking-[0.16px] capitalize'
                >
                    {option}
                </p>
            </div>
            <img src="/images/icons/chevron-down-outline.svg" alt="" />
        </div>
    )
}

export default SelectWithLabelOption