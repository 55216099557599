import '../App.css';
import React from 'react';
import { Footer } from '../Components/Footer';
import OrangeButton from '../Components/buttons/orange-button';
import ContactTestimonialCarousel from '../Components/contact/ContactTestimonials';
import HeroContainer from '../Components/ui/hero-container';


export default function Contact() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div>
      <HeroContainer>
        <div className="w-full">
          <div className="container relative mx-auto px-4 py-8 lg:py-12">
            <div className="flex flex-col lg:flex-row gap-8 pb-24 lg:pb-0 items-center">
              {/* Left Content */}
              <div className="lg:w-1/2 space-y-8 text-white">
                <h1 className="hero-title">
                  We'd love to help
                </h1>
                <p className="w-[80%] mx-auto lg:w-full md:ml-0 text-[#678285] text-center md:text-left font-[Fredoka]  text-[19px] md:text-[22px] font-medium leading-[32px] tracking-[-0.22px]">
                  Reach out to us by sending us a message and we'll get back to you within the next 24 hours
                </p>
                <div className="flex md:justify-start justify-center">
                  <div className="space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="bg-teal-200 p-2 rounded-full">
                        <svg className="w-5 h-5 text-teal-900" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </div>
                      <span className="text-white font-[Fredoka] text-[22px] font-medium leading-[32px] tracking-[-0.22px]">info@iamai.solutions</span>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="bg-yellow-200 p-2 rounded-full">
                        <svg className="w-5 h-5 text-yellow-900" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                      </div>
                      <span className="text-white font-[Fredoka] text-[22px] font-medium leading-[32px] tracking-[-0.22px]">+1 0000 000 000</span>
                    </div>
                  </div>
                </div>

                <div className="hidden lg:block">
                  <img
                    src="/images/contact-hero.png"
                    alt="Happy students"
                    className="rounded-lg w-full max-w-[400px] object-cover"
                  />
                </div>
              </div>

              {/* Right Form */}
              <div className="max-w-[500px] absolute lg:top-10 lg:right-0 top-[80%] mx-4">
                <div className="bg-[#E0F5F5] rounded-[24px] p-6 space-y-8 shadow-lg">
                  <h2 className="text-[#012E33] text-center font-[Fredoka] text-[19px] md:text-[32px] font-medium leading-normal tracking-[-0.64px]">Send us a message</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className='space-y-2'>
                      <label className="text-[#012E33] font-[Fredoka] text-[20px] font-normal leading-[30px]">
                        First Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your First Name"
                        className="w-full px-3 py-2 focus:outline-none focus:ring-2 rounded-[16px] border border-[#DCDCDC] bg-white text-[#678285] font-[Fredoka] text-[16px] font-normal leading-normal tracking-[-0.16px]"
                      />
                    </div>

                    <div className='space-y-2'>
                      <label className="text-[#012E33] font-[Fredoka] text-[20px] font-normal leading-[30px]">
                        Last Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your Last Name"
                        className="w-full px-3 py-2 focus:outline-none focus:ring-2 rounded-[16px] border border-[#DCDCDC] bg-white text-[#678285] font-[Fredoka] text-[16px] font-normal leading-normal tracking-[-0.16px]"
                      />
                    </div>

                    <div className='space-y-2'>
                      <label className="text-[#012E33] font-[Fredoka] text-[20px] font-normal leading-[30px]">
                        Email*
                      </label>
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        className="w-full px-3 py-2 focus:outline-none focus:ring-2 rounded-[16px] border border-[#DCDCDC] bg-white text-[#678285] font-[Fredoka] text-[16px] font-normal leading-normal tracking-[-0.16px]"
                      />
                    </div>

                    <div className='space-y-2'>
                      <label className="text-[#012E33] font-[Fredoka] text-[20px] font-normal leading-[30px]">
                        Company/Organisation Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your Company/Organisation Name"
                        className="w-full px-3 py-2 focus:outline-none focus:ring-2 rounded-[16px] border border-[#DCDCDC] bg-white text-[#678285] font-[Fredoka] text-[16px] font-normal leading-normal tracking-[-0.16px]"
                      />
                    </div>

                    <div className='space-y-2'>
                      <label className="text-[#012E33] font-[Fredoka] text-[20px] font-normal leading-[30px]">
                        How would you like to partner?
                      </label>
                      <textarea
                        rows="4"
                        placeholder="Write a message"
                        className="w-full px-3 py-2 focus:outline-none focus:ring-2 rounded-[16px] border border-[#DCDCDC] bg-white text-[#678285] font-[Fredoka] text-[16px] font-normal leading-normal tracking-[-0.16px]"
                      ></textarea>
                    </div>

                    <div className='w-full flex justify-center'>
                      <OrangeButton label={"Submit"} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroContainer>
      <div className='mt-[760px] lg:mt-12'>
        <ContactTestimonialCarousel />
      </div>
      <Footer />
    </div>
  );
}
