import React, { useEffect, useRef } from 'react';
import './educationCardStyle.css';

const EducationServicesCard = ({ setOpenSolutionCard, openSolutionCard }) => {
  const cardRef = useRef(null); // Reference to the card div

  // Close the card if click happens outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setOpenSolutionCard(false); // Close the card
      }
    };

    // Adding the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenSolutionCard]);

  const services = [
    {
      icon: <img src='/images/icons/GraduationCap.svg' alt="Graduation Cap" />,
      title: "Academic Class Spaces",
      description: "Personalised Learning Tool"
    },
    {
      icon: <img src='/images/icons/BookOpen.svg' alt="Book Open" />,
      title: "College Counselling",
      description: "Find Universities that match"
    },
    {
      icon: <img src='/images/icons/TestTube.svg' alt="Test Tube" />,
      title: "Standardized Testing",
      description: "Prepare for ACT / SAT"
    }
  ];

  return (
    <div className={`solutionCard ${openSolutionCard ? "sol-active" : ""}`} ref={cardRef}>
      {services.map((service) => (
        <div key={service.title} className="service-item">
          <div className="icon-container">
            {service.icon}
          </div>
          <div className="content">
            <h3 className="title">{service.title}</h3>
            <p className="sol-description">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EducationServicesCard;
