import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Footer } from '../Components/Footer';

const StandardizedTesting = () => {
    const publicUrl = process.env.PUBLIC_URL;

    const heroTitle = "Standardized Testing";
    const heroSubtitle = "Achieve your best scores with our AI-powered test-prep and tutoring services.";

    const actTitle = "ACT Personalized AI Test-Prep Platform";
    const actDescription = "Our ACT test-prep platform uses AI to create a personalized study plan tailored to your strengths and weaknesses.";
    const actTutoringTitle = "ACT Tutoring";
    const actTutoringDescription = "Get one-on-one tutoring sessions with our expert tutors to help you excel in the ACT.";
    const actImg = publicUrl + "/images/act.png";

    const satTitle = "SAT Personalized AI Test-Prep Platform";
    const satDescription = "Our SAT test-prep platform uses AI to create a personalized study plan tailored to your strengths and weaknesses.";
    const satTutoringTitle = "SAT Tutoring";
    const satTutoringDescription = "Get one-on-one tutoring sessions with our expert tutors to help you excel in the SAT.";
    const satImg = publicUrl + "/images/sat.png";

    return (
        <div className="StandardizedTesting">
            {/* <Header /> */}
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    color: 'white',
                    textAlign: 'center',
                    py: 10,
                    backgroundImage: `url(${publicUrl}/images/standardized-testing-hero.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'brightness(100%)',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    }}
                />
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h2" gutterBottom>{heroTitle}</Typography>
                    <Typography variant="h6">
                        {heroSubtitle}
                    </Typography>
                </Box>
            </Box>


            {/* ACT Section */}
            <Container sx={{ py: 8 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        <img src={actImg} alt="ACT" style={{ width: '50%', borderRadius: '8px', maxHeight: '400px', objectFit: 'cover' }} />
                    </Box>
                    <Typography variant="h4" gutterBottom>{actTitle}</Typography>
                    <Typography variant="body1" mb={2}>
                        {actDescription}
                    </Typography>
                    <Typography variant="h5" gutterBottom>{actTutoringTitle}</Typography>
                    <Typography variant="body1">
                        {actTutoringDescription}
                    </Typography>
                </Box>
            </Container>

            {/* SAT Section */}
            <Container sx={{ py: 8 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        <img src={satImg} alt="SAT" style={{ width: '50%', borderRadius: '8px', maxHeight: '400px', objectFit: 'cover' }} />
                    </Box>
                    <Typography variant="h4" gutterBottom>{satTitle}</Typography>
                    <Typography variant="body1" mb={2}>
                        {satDescription}
                    </Typography>
                    <Typography variant="h5" gutterBottom>{satTutoringTitle}</Typography>
                    <Typography variant="body1">
                        {satTutoringDescription}
                    </Typography>

                </Box>
            </Container>

            <Footer />
        </div>
    );
}

export default StandardizedTesting;
