import React from 'react';
import { CERTIFICATES_DATA, DUMMY_ORGANISATION_LOGO_URL } from '../constants/Certifications';

const CertificateCard = ({ title, organization, issueDate, certificateImage }) => {
    return (
        <div className="w-full flex md:flex-row  gap-3 flex-col items-center p-2 pl-6 bg-[#EFF7F7] rounded-2xl">
            <div className="flex items-center gap-[18px] flex-1">
                <img
                    src={DUMMY_ORGANISATION_LOGO_URL}
                    alt="Organization logo"
                    className="w-14 h-14 object-contain"
                />

                <div className="flex-1 space-y-[6px]">
                    <h3 className="text-[#012E33] text-base leading-normal font-normal -tracking-[0.32px]">{title}</h3>
                    <p className="text-[#678285] text-base leading-normal font-normal -tracking-[0.32px]">{organization}</p>
                    <p className="text-[#678285] text-base leading-normal font-normal -tracking-[0.32px]">{issueDate}</p>
                </div>
            </div>

            <img
                src={certificateImage}
                alt="Certificate preview"
                className="w-36 md:w-28 h-[101px] object-cover"
            />
        </div>
    );
};

const Certifications = () => {


    return (
        <div className="flex flex-col gap-2">
            {CERTIFICATES_DATA.map((cert, index) => (
                <CertificateCard
                    key={index}
                    title={cert.title}
                    organization={cert.organization}
                    issueDate={cert.issueDate}
                    certificateImage={cert.certificateImage}
                />
            ))}
        </div>
    )
};

export default Certifications;