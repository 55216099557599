import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { BOOKING_TIMES } from '../constants/SearchTutor';
import generateRandomDisabledDays from '../utils/generateRandomDisabledDays';
import ShadowBtn from '../../../Components/ShadowBtn';
import CustomCalendar from '../../../Components/CustomCalendar';

const BookingModal = ({ tutorname, step, setStep, setShowAppointmentModal, selectedDate, setSelectedDate, selectedTime, setSelectedTime }) => {

    const handleDateConfirm = () => {
        if (selectedDate) setStep(2);
    };

    const handleTimeConfirm = () => {
        if (selectedTime) setStep(3);
    };

    const resetBooking = () => {
        setSelectedDate(null);
        setSelectedTime(null);
        setShowAppointmentModal(false)
        setStep(1)
        setSelectedDate(new Date())
    };

    const disabledDays = useMemo(() => {
        return generateRandomDisabledDays(5);
    }, [])

    return (

        <div className="bg-white py-6 px-[88px] rounded-lg shadow-lg w-full min-w-[680px]">
            {step === 1 && (
                <>
                    <h2 className="text-2xl fredoka leading-9 text-[#012E33] font-medium text-center mb-6 ">{tutorname} Availability</h2>
                    <CustomCalendar selected={selectedDate} onSelect={setSelectedDate} disabledDays={disabledDays} />
                    <div className='flex items-center mt-8'>
                        <ShadowBtn text='Confirm Date' onClick={handleDateConfirm} />
                    </div>

                </>
            )}

            {step === 2 && (
                <div>
                    <h2 className="text-2xl fredoka leading-9 text-[#012E33] font-medium text-center mb-6 ">{tutorname} Availability</h2>
                    <div className=' flex gap-4 items-center justify-center mb-8'>
                        <img src="/icons/chevron-left.svg" alt="" />
                        <p className=' text-teal-900 font-bold text-center fredoka text-base leading-5'>{`${selectedDate ? format(selectedDate, "do MMMM yyyy") : ''}`}</p>
                        <img src="/icons/chevron-right.svg" alt="" />
                    </div>

                    {
                        BOOKING_TIMES.map((Times, index) => (
                            <div key={index} className="flex flex-wrap justify-center gap-4 mb-2">
                                {Times.map((time) => (
                                    <button
                                        key={time}
                                        onClick={() => setSelectedTime(time)}
                                        className={`px-6 fredoka py-3 rounded-full text-base
                                            transition-colors border ${selectedTime === time
                                                ? 'bg-[#0E9F6E] border-[#0E9F6E] text-white'
                                                : 'border-[#014451] text-[#678285]'
                                            }
                                            hover:bg-[#0E9F6E] hover:border-[#0E9F6E] hover:text-white
                                        `}
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        ))
                    }

                    <div className='flex items-center mt-4'>
                        <ShadowBtn text='Confirm Date' onClick={handleTimeConfirm} />
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className=" flex flex-col gap-[19px] mx-auto text-center items-center justify-center w-[400px]">
                    <div className=' flex flex-col gap-2 items-center justify-center'>
                        <img src="/images/icons-celebration.png" alt="" className='w-20 h-20 object-cover' />
                        <p className='fredoka text-xl leading-7 font-medium text-[#012E33]'>Great Work!</p>
                    </div>
                    <p className='text-2xl leading-9 font-semibold text-[#012E33] fredoka'>Your Free Tutoring Session is booked for {selectedDate ? format(selectedDate, "do MMMM yyyy") : ''} between {selectedTime}</p>
                    <p className=" text-sm leading-5 fredoka text-center text-gray-500 fredoka">You'll receive an email confirmation and a calendar invite on your registered email address.</p>

                    <ShadowBtn text='Continue' onClick={resetBooking} />
                </div>
            )}
        </div>

    );
};

export default BookingModal;
