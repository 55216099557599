import HeroContainer from '../ui/hero-container'
import { Footer } from '../Footer'

export default function Policy() {
    return (<>
        <HeroContainer>
            <div className='h-36 container !mt-8 '>
                <span className='hero-title'>Terms & Conditions</span>
            </div>
        </HeroContainer>
        <div className="container">
            <div className='mt-12 my-8'>
                <span className='text-[#012E33] !mt-12 text-4xl font-[Fredoka] font-medium'>About</span>
            </div>
            <div className='max-w-[851px] flex flex-col gap-6 mb-12 lg:mb-44'>

                <p className=" text-[#678285] text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium sed lorem nec feugiat. Nam placerat, elit eget rutrum vulputate, ex nulla aliquam enim, vitae auctor enim sapien a justo. Mauris vestibulum ligula eros, sit amet rutrum est fermentum nec. Proin eget rutrum odio. Praesent tempus volutpat aliquet. Proin eu mi erat. Aenean porta enim lectus, quis tincidunt ligula consequat quis. Curabitur iaculis egestas condimentum. Vivamus vestibulum varius nunc, vitae pretium urna bibendum quis. Vestibulum bibendum pellentesque odio. Phasellus enim justo, sodales at egestas nec, malesuada vitae quam. Donec quis ipsum at lacus dictum vulputate ut vitae nisl. Nunc eu felis lacus.
                </p>
                <p className=" text-[#678285] text-base">
                    Donec interdum quis nisi eget faucibus. Donec congue neque eget pretium egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin fermentum ultrices volutpat. Phasellus libero justo, tempus ut augue sit amet, fringilla facilisis arcu. Quisque bibendum orci vel elit faucibus, sed eleifend magna sollicitudin. Aenean sollicitudin felis vitae tellus tristique fermentum. Nunc nulla ante, rhoncus sed ultricies sit amet, condimentum a turpis. Etiam ac leo et mauris fringilla pharetra vel at purus.
                </p>
            </div>
        </div>
        <Footer />
    </>
    )
}

