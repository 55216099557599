import React from 'react';
import StarRating from './StarRating';

const ProfileDetail = ({ tutorDetails }) => {
    return (
        <>
            <div className="flex justify-between items-start pb-4 px-3">
                <div className='flex flex-col gap-2'>
                    <h1 className="text-[#012E33] text-[40px] font-bold leading-none -tracking-[0.8px]">{tutorDetails?.tutor_name || "Isabel C."}</h1>
                    <p className="text-[#678285] text-lg leading-normal -tracking-[0.36px]">
                        Houston, Texas
                    </p>
                </div>
                <div className="text-[#012E33] text-[40px] font-normal -tracking-[0.8px]">
                    {`$${tutorDetails?.tutor_hourly_rate}/hr`}
                </div>
            </div>
            <div className="flex items-center justify-between pb-6 px-3">
                <div className='flex items-center gap-2'>
                    <img alt='education' src='/images/icons/education-black.svg' className='w-8 h-8 object-contain' />
                    <span className="text-[#012E33] text-lg font-normal -tracking-[0.36px]">{tutorDetails?.subjects || "Italian"}</span>
                </div>
                <div className="flex flex-row-reverse gap-2 md:flex-row items-center md:gap-[15px] text-[#678285]">
                    <span className='font-normal mt-1 text-base -leading-[0.32px] self-end'>{`${tutorDetails?.rating || 4}/5`}</span>
                    <StarRating rating={tutorDetails?.rating || 4} />
                    <span className='hidden md:block font-normal text-base underline -leading-[0.32px]'>77 Ratings</span>
                </div>
            </div>

            <p className="text-[#678285] text-lg font-normal -tracking-[0.36px] pb-[69px] px-3">
                TESOL-certified teacher with 6+ years of experience teaching all levels, specialized in teaching beginners. I use personalized lessons and proven methods to help you confidently master English. Let’s take your skills to the next level!
            </p>
        </>
    );
};

export default ProfileDetail;