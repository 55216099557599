
export const REVIEWS_DATA = [
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
    {
        rating: 4,
        comment: "Mina is extremely friendly and very dynamic. She explains the concepts with patience and is",
        name: "Miriam",
        date: "Sept 18, 2024"
    },
];