import React, { useState } from 'react'

const CustomSelect = ({ children, options, onSelect, className = "" }) => {

    const [open, setOpen] = useState(false);

    return (
        <div className={`${className} flex-1 relative w-fit hover:cursor-pointer`} onClick={() => setOpen(!open)}>
            {children}

            {
                open &&
                <div className='absolute z-50 w-full rounded-lg p-3 bg-white shadow-md'>
                    <ul className="flex flex-col gap-2">
                        {options?.map(({ value }) => (
                            <li
                                key={value}
                                className="p-1.5 text-sm font-normal text-gray-900 flex gap-2 rounded-[4px] hover:bg-gray-100 transition-all cursor-pointer capitalize"
                                onClick={() => onSelect?.(value)}
                            >
                                {value}
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    )
}

export default CustomSelect