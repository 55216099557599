import React from 'react'

const HeroContainer = ({ children, className }) => {
    return (
        <div className={`${className} bg-[#012E33] pt-8 rounded-b-[40px]`}>
            {children}
        </div>
    )
}

export default HeroContainer