"use client";

import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import "./carouselStyle.css";
import AutoScroll from 'embla-carousel-auto-scroll'


const Carousel = ({ children, className }) => {
    const [emblaRef] = useEmblaCarousel({ loop: true }, [AutoScroll({ playOnInit: true })]);

    return (
        <div className="embla" ref={emblaRef}>
            <div className={`${className} flex gap-3 embla__container`}>
                {children}
            </div>
        </div>
    );
};

export default Carousel;