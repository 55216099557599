
export const SCHEDULE_DATA = [
    {
        day: 'Tuesday',
        timeSlots: [
            '7 PM - 8 PM',
            '8 PM - 9 PM',
            '9 PM - 10 PM',
            '10 PM - 11 PM'
        ]
    },
    {
        day: 'Friday',
        timeSlots: [
            '7 PM - 8 PM',
            '8 PM - 9 PM',
            '9 PM - 10 PM',
            '10 PM - 11 PM'
        ]
    }
];