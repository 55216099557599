import '../App.css';
import React, { useState } from 'react';
import { HeroSection } from '../Components/HeroSection';
// import { VideoSectionComingSoon } from '../Components/VideoSectionComingSoon';
// import { CallToActionSection } from '../Components/CallToActionSection';
// import { NewsletterSignupSection } from '../Components/NewsletterSignupSection';
// import { ContactUsSection } from '../Components/ContactUsSection';
import { Footer } from '../Components/Footer';
// import { Divider } from '@mui/material';
import Carousel from '../Components/ui/carousel';
import StudentSection from '../Components/home/StudentSection';
import AcademicSpaces from '../Components/home/AcademicSpaces ';
import CollegeCounsellingSection from '../Components/home/CollegeCounsellingSection';
import StandardizedTesting from '../Components/home/StandarizedTestingSection';
import data from "../Pages/tutor/data/Tutors.json";
// import TeacherCard from '../Components/home/TeacherCard';
import TestimonialSlider from '../Components/home/TestimonialsSection';
import FreeTrial from '../Components/about-page/free-trial';
import CustomModal from '../Components/CustomModal';
import BookingModal from './tutor/components/BookingModal';
import TutorCard from './tutor/components/TutorCard';
function Home() {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false)
  const [tutorName, setTurorName] = useState("")
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);

  function bookAppointment(tutor) {
    setTurorName(tutor)
    setShowAppointmentModal(true)
  }
  function onModalCLose() {
    setShowAppointmentModal(false)
    setStep(1)

    setSelectedTime(new Date())
    setSelectedDate(null)
  }
  return (
    <div className="Home">
      <CustomModal isOpen={showAppointmentModal} onClose={onModalCLose}>
        <BookingModal
          setStep={setStep}
          step={step}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          tutorname={tutorName}
          setShowAppointmentModal={setShowAppointmentModal} />
      </CustomModal>
      {/* <Header /> */}
      <HeroSection />

      {/* <VideoSection /> */}
      {/* <VideoSectionComingSoon /> */}
      <StudentSection />
      <StandardizedTesting />
      {/* <TestimonialsSection /> */}
      {/* <Divider /> */}
      <AcademicSpaces />
      <CollegeCounsellingSection />
      <div className='container !mt-14 flex flex-wrap gap-12'>
        <Carousel>
          {data?.map((tutor, index) => {
            return (
              <div className='flex-none md:max-w-[33%] max-w-[250px]'>
                <TutorCard
                  key={index}
                  data={tutor}
                  bookAppointment={bookAppointment}
                />
              </div>
            )
          })}
        </Carousel>
      </div>
      <TestimonialSlider />
      <div className='!my-14 container'>
        <FreeTrial />
      </div>
      {/* <CallToActionSection />
      <Divider />
      <NewsletterSignupSection />
      <Divider />
      <ContactUsSection /> */}
      <Footer />
    </div>
  );
}

export default Home;
