import React from 'react';
import OrangeButton from '../buttons/orange-button';
import OrangeButtonOutline from '../buttons/orange-button-outline';

const AcademicSpaces = () => {
    const features = [
        'Subject Specific Class Spaces',
        'Personalised Study Guides',
        'Practice Modules',
        'Chat with your AI Tutor',
        'Tutor-Integrated',
        'Parent Account Linking'
    ];

    return (
        <div className="!mt-14 mx-auto flex flex-col-reverse md:flex-row gap-12 items-center">
            <div className="w-full mx-auto md:!pl-24 container md:w-1/2  space-y-6">
                <div className="space-y-4">
                    <h2 className="text-[#012E33] fredoka text-4xl font-bold leading-normal tracking-[-0.8px]">
                        AI-Powered Academic <br /> Class Spaces
                        <span role="img" aria-label="robot" className="text-2xl">🤖</span>
                    </h2>

                    <p className="text-[#678285] fredoka text-base font-normal leading-8 tracking-[-0.22px]">
                        Academic Class Spaces are customized tools that help your child stay organized, focused, and on track. Tailored to their unique needs, these AI-powered spaces support better learning and help them achieve their academic goals. Empower your child to succeed!
                    </p>
                </div>

                <div className="space-y-3">
                    {features.map((feature, index) => (
                        <div key={index} className="flex items-center gap-3">
                            <div className="bg-yellow-100 flex items-center justify-center w-10 h-10 rounded-full p-1">

                                <img className="w-6 h-6" src="/images/icons/CheckFat.svg" alt="" />
                            </div>
                            <span className="text-[#012E33] font-fredoka text-base font-semibold leading-8 tracking-[-0.22px]">{feature}</span>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col md:flex-row gap-4 pt-4">
                    <OrangeButton label={"Try Academic Class Spaces"} />
                    <OrangeButtonOutline label={"Learn More"} />
                </div>
            </div>
            <div className='md:w-1/2 w-full md:p-0 pl-4'>
                <img src="/images/home-browser.png" alt="" />
            </div>
        </div>
    );
};

export default AcademicSpaces;