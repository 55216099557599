function generateRandomDisabledDays(numDays) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const today = currentDate.getDate();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const disabledDays = new Set();

    while (disabledDays.size < numDays) {
        const randomDay = Math.floor(Math.random() * daysInMonth) + 1;
        if (randomDay !== today) {
            disabledDays.add(new Date(currentYear, currentMonth, randomDay));
        }
    }

    return Array.from(disabledDays);
}

export default generateRandomDisabledDays;