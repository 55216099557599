import React from 'react'
import Carousel from '../ui/carousel';

const StudentSection = () => {
    // const stats = [
    //     {
    //         value: '35k',
    //         label: 'Students Coached',
    //         valueColor: 'text-[#66CDCC]'
    //     },
    //     {
    //         value: '40%',
    //         label: 'Score Increased',
    //         valueColor: 'text-[#FC823E]'
    //     },
    //     {
    //         value: '99%',
    //         label: 'Success Rate',
    //         valueColor: 'text-[#66CDCC]'
    //     },
    //     {
    //         value: '100+',
    //         label: 'Tutors',
    //         valueColor: 'text-[#FC823E]'
    //     },
    //     {
    //         value: '32',
    //         label: 'School Partners',
    //         valueColor: 'text-[#66CDCC]'
    //     }
    // ];

    // const unis = [
    //     "/uni/kss.png",
    //     "/uni/uni.png",
    //     "/uni/uni-2.png",
    //     "/uni/rice-university.png",
    //     "/uni/StrakeJeb.png",
    //     "/uni/sjs.png",
    //     "/uni/uni-3.png",
    //     "/uni/uni-4.png",
    //     "/uni/stagnes-mascot.png",
    // ]

    return (
        <div className='!mt-14 flex-col flex gap-6 container'>
            <h1 className="text-[#012E33] text-center fredoka text-4xl font-bold leading-normal tracking-tight"
            >Trusted by students at</h1>

            {/* <div className='flex gap-2 justify-between items-center'> */}
            <Carousel className={"flex justify-between !gap-14"}>
                <img src="/uni/kss.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/uni.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/uni-2.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/rice-university.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/texas-logo.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/StrakeJeb.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/sjs.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/stagnes-mascot.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/kss.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/uni.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/uni-2.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/rice-university.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/texas-logo.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/StrakeJeb.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/sjs.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
                <img src="/uni/stagnes-mascot.png" className="object-contain flex-none md:w-[90px] h-[90px]" alt="" />
            </Carousel>

            {/* </div> */}

            {/* <div className="w-full max-w-6xl mx-auto px-4 py-8">
                <div className="flex space-y-11 flex-wrap justify-around items-center gap-8 md:gap-4">
                    <div className='h-[0.6px] bg-[#D9DFDF] w-full'></div>
                    {stats.map((stat, index) => (
                        <div key={index} className="text-center md:text-left">
                            <div className={`text-5xl tracking-tight fredoka font-bold md:text-5xl mb-2 ${stat.valueColor}`}>
                                {stat.value}
                            </div>
                            <div className="text-[#678285] fredoka text-base font-medium leading-8 tracking-tight"
                            >
                                {stat.label}
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    )
}

export default StudentSection