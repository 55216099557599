import React from 'react';
import OrangeButton from '../buttons/orange-button';
import OrangeButtonOutline from '../buttons/orange-button-outline';

const StandardizedTesting = () => {
    const features = [
        'ACT & SAT Test Preparation Platform',
        'One-on-one tutoring sessions with experts'
    ];

    return (
        <div className="container !mt-14 mx-auto md:p-8">
            <div className="bg-[#EFF7F7] rounded-[56px] relative overflow-hidden">
                {/* Content Container */}
                <div className="flex flex-col md:flex-row items-center justify-between relative z-10">
                    {/* Left Content Section */}
                    <div className="w-full md:w-1/2 space-y-6 md:p-14 p-7  mb-8 md:mb-0">
                        <div className="space-y-4">
                            <h2 className="text-3xl fredoka text-center md:text-left md:text-4xl font-bold text-[#0A2942]">
                                Standardized Testing
                            </h2>

                            <p className="text-gray-600 fredoka text-center md:text-left">
                                Achieve your best scores with our AI-powered test-prep and tutoring services.
                            </p>
                        </div>

                        <div className="space-y-3 ">
                            {features.map((feature, index) => (
                                <div key={index} className="flex items-center justify-center md:justify-start gap-3">
                                    <div className="bg-[#7ED5D0] rounded-full p-1.5">
                                        <img className="w-6 h-6" src="/images/icons/yellow-check.svg" alt="" />
                                    </div>
                                    <span className="text-gray-700 fredoka">{feature}</span>
                                </div>
                            ))}
                        </div>

                        <div className="flex flex-wrap gap-4 pt-4">
                            <OrangeButton label={"Try Standardized Testing"} />
                            <OrangeButtonOutline label={"Learn More"} />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 md:mb-[-69px] flex justify-center items-center relative">
                        {/* Student Image Placeholder */}
                        <div className="relative">
                            <img
                                src="/images/test-img.png"
                                alt="Student"
                                className="w-[500px]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StandardizedTesting;