import React from 'react';
import { Container, Typography, Box, Grid, Button, Card, CardContent, CardMedia } from '@mui/material';
import { Footer } from '../Components/Footer';
import { Link } from 'react-router-dom';



const Partnerships = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const partners = [
    { name: 'Doran and Scalora', logo: publicUrl + '/images/doran-logo.webp', description: 'Robin Doran and Jennifer Scalora introduced doran+scalora college consulting in the summer of 2014. Robin, a former Head Dean of The Kinkaid School, and Jennifer, the long-time Director of Admissions for the Plan II Honors Program at the University of Texas at Austin, bring decades of experience to an adaptive, personalized approach to college admission counseling.', url: "https://www.doranandscalora.com/" },
    // { name: 'Partner 2', logo: 'path/to/logo2.png', description: 'Description of Partner 2' },
  ];

  return (
    <div className="flex-container">
      {/* <Header /> */}
      <div className="content">
        <Container>
          <Box sx={{ textAlign: 'center', my: 4 }}>
            <Typography variant="h2" gutterBottom>
              Our Partners
            </Typography>
            <Typography variant="body1" gutterBottom>
              We are proud to collaborate with these outstanding organizations.
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {partners.map((partner, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <a href={partner.url} target="_blank" rel="noopener noreferrer">
                    <CardMedia
                      component="img"
                      alt={partner.name}
                      height="140"
                      image={partner.logo}
                    />
                  </a>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {partner.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {partner.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: 'center', my: 4 }}>
            <Typography variant="h4" gutterBottom>
              Become a Partner
            </Typography>
            <Typography variant="body1" gutterBottom>
              Interested in partnering with us? We would love to hear from you.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/contact"
            >
              Contact Us
            </Button>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Partnerships;
