import React, { useState } from 'react';
import { TIME_LINE_DATA } from '../constants/Experience';

const TimelineItem = ({ title, period, descriptions }) => (
    <div className='flex gap-2 group'>
        <div className='flex flex-col items-center'>
            <div className="border-r h-[7px] group-first:h-0 border-dashed border-[#66CDCC]"></div>
            <div className="w-2.5 h-2.5 mt-0 group-first:mt-[7px] rounded-full bg-teal-400"></div>
            <div className="border-r flex-1 border-dashed border-[#66CDCC]"></div>
        </div>
        <div className='w-[calc(100%-18px)] pb-8 group-last:pb-0'>
            <div>
                <div className='flex flex-col gap-[5px]'>
                    <h3 className="text-base font-normal text-[#012E33] leading-normal -tracking-[0.32px]">{title}</h3>
                    <p className="text-base font-normal text-[#678285] leading-normal -tracking-[0.32px]">{period}</p>
                </div>
                <div className="pl-4">
                    {descriptions.map((description, index) => (
                        <ul
                            key={index}
                            className="mb-3 list-disc last:mb-0"
                        >
                            <li className="text-base font-normal text-[#678285] leading-normal -tracking-[0.32px]">{description}</li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

const Timeline = ({ items, initialItemsToShow = 1 }) => {
    const [showAll, setShowAll] = useState(false);

    const displayedItems = showAll
        ? items
        : items.slice(0, initialItemsToShow);

    return (
        <div className="max-w-2xl mx-auto">
            <div className="pb-7">
                {displayedItems.map((item, index) => (
                    <TimelineItem
                        key={index}
                        title={item.title}
                        period={item.period}
                        descriptions={item.descriptions}
                    />
                ))}
            </div>

            {items.length > initialItemsToShow && (
                <button
                    onClick={() => setShowAll(!showAll)}
                    className="px-6 py-2 rounded-2xl flex justify-center items-center h-10 border border-[#66CDCC] w-fit"
                >
                    <span className='text-base font-semibold text-[#66CDCC] leading-[1] tracking-[0.5px]'>{showAll ? 'Show Less' : 'See More...'}</span>
                </button>
            )}
        </div>
    );
};

const Experience = () => {

    return <Timeline items={TIME_LINE_DATA} initialItemsToShow={2} />;
};

export default Experience;