import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";

const CustomCalendar = ({ selected, onSelect = () => { }, disabledDays = [] }) => {

    return (
        <DayPicker
            mode="single"
            showOutsideDays={true}
            selected={selected}
            onSelect={onSelect}
            disabled={disabledDays}
            className='m-0 w-full'
            classNames={{
                months: "max-w-full flex flex-col items-center",
                month: "w-full",
                month_caption: "flex w-full h-5 items-center justify-center",
                caption_label: "text-center text-[16px] font-[700] text-[#014451] leading-[1.5]",
                nav: "flex w-[160px] h-[20px] items-center absolute justify-between",
                button_previous: "text-teal-900 h-[16px] w-[16px] p-0 cursor-pointer",
                button_next: "text-teal-900 h-[16px] w-[16px] p-0 cursor-pointer",
                chevron: "fill-teal-900 w-full h-full",
                month_grid: "w-[calc(100%-16px)] mt-[24px] mr-[8px] mb-[8px] ml-[8px]",
                weekday: "text-center text-[16px] font-[700] text-[#014451] leading-[1.5]",
                outside: "text-[#9CA3AF]",
                day: "text-center text-[#014451] border-r border-r-[#F3F4F6] border-b border-b-[#F3F4F6] leading-[1.5]",
                day_button: "w-full py-[9.5px] text-center text-[14px] font-[500] border-none outline-[0px] leading-[1.5]",
                today: `${!selected ? "bg-[#014451] text-white" : "text-[#014451]"}`,
                selected: "bg-[#014451] text-white",
                disabled: "text-red-500"
            }}
        />
    );
};

export default CustomCalendar;