import React from 'react'
import './heroStyle.css'
import OrangeButton from '../../buttons/orange-button'
import OrangeButtonOutline from '../../buttons/orange-button-outline'
import HeroContainer from '../../ui/hero-container'
const AboutHeroSection = () => {
    return (
        <>
            <HeroContainer className="landing-section">
                <p className="tagline fredoka">I AM Always improving 🚀</p>
                <h1 className="headline hero-title !font-semibold">
                    Empowering tomorrow's leaders
                    <br />
                    through personalized, <br />
                    tech-driven education
                    <p className='emoji-pos-robot'>
                        <img src="/images/about/robot.png" alt="" />
                    </p>
                    <p className='emoji-pos-rocket'>
                        <img src="/images/about/rocket.png" alt="" />
                    </p>
                    <p className='emoji-pos-laptop'>
                        <img src="/images/about/laptop.png" alt="" />
                    </p>
                    <p className='emoji-pos-book'>
                        <img src="/images/about/book.png" alt="" />

                    </p>
                </h1>
                <p className="description">
                    At [IAMAI], we unlock students' potential through personalized learning, equipping them with the tools to thrive in a constantly evolving world.
                </p>
                <div className="button-container">
                    <OrangeButton label="Join our waitlist" />
                    <OrangeButtonOutline label="Start Free Trial" />
                </div>
            </HeroContainer>
            <div className='about-hero-img'>
                <img className='about-hero-img-1' src="/images/about/about-hero-img.png" alt="" />
                <img className='about-hero-img-2' src="/images/about/about-hero-mobile-img.png" alt="" />
            </div>
        </>
    )
}

export default AboutHeroSection