import React from 'react'
import './freeTrial.css'
import OrangeButton from '../../buttons/orange-button'
const FreeTrial = () => {
    return (
        <div className='free-trial'>
            <div className='free-trial-text'>
                <h1 className='fredoka'>Leverage AI and realize your full potential. Join our community.</h1>
                <div className='ft-btn-container'>
                    <OrangeButton label={"Start Free Trial"} />
                </div>
            </div>
            {/* <div className='free-trial-box-parent'>
                <div className="free-trial-box">
                    <h4>IAMAI improved my scores by</h4>
                    <p>40%</p>
                </div>
            </div> */}
        </div>
    )
}

export default FreeTrial