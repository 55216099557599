
const DUMMY_CERTIFICATE_URL = "https://s3-alpha-sig.figma.com/img/c0ed/74f6/37543a65cc50dfe51ad89402c0da8f2e?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Zj8BXEQsxwkvp~BujW2nBvXU9A45-rCD7oJZ3cp2fEnxrNqcXkSKVovkim~EdJ933vi1ctyDPbtOHIM5m1onP-Rw6FgX2ErSiGQ5rdjJjQcrFWwx8RolaZRWMNztFuoU0brM1P9xJV2XvoVI7dMD-NsD1eEU1Y787~iiRUmm0CZISsbfyQSJ7U1G1-1QH6LOa155vh2wUpSpr46q52NWPGJKjN~zkzutP7Gh-ePtOZPYaaG5g6TtfvxW10QOPJOMo10DHI0d0E3HIzqdwSmY-vtay7TEk19sABn6MTIaBXBJ7R3AwwESxhUsZcw-IMToVSkh5OL3Tome5Bu3DMN6Rw__"

export const DUMMY_ORGANISATION_LOGO_URL = "https://s3-alpha-sig.figma.com/img/90ab/1520/8e66ecdc9b6b06e31c4e7b9dc791c0e5?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=N-aQ33JNzKgMQOwQBH0qnLiVRkKMc4oStPGEu40ZdgA8QarkWA7I0x6hRVOdO5XbS64feKeZKxqAuFY4NvslnEnE4QwUf~QmJrfdZV9rsHQn0~YM-cO9ijxqc4sANDf2tVP5mMaB8IQDh7PhDFAZ8Qak6AUKWSDMgReRu7q2rqat5bMLpOtygpaETKrHGHi-heL0iFhkSVTAXqD4VKRoB2xFXY8vryoTatTJY0ejLFmOr3GvZaIrW5yD0TRoPQfN19cL-aTc5r0WWZ250hcAJbpZqGcR3HVMFzbQY5FBVjpjK7gZoJ1W7XIyDIog0xESJR3M5jD7dKBtP~OQP2wK~Q__"

export const CERTIFICATES_DATA = [
    {
        title: "English Level 5 Training Certificate",
        organization: "Organization Name",
        issueDate: "Issued March 2022",
        certificateImage: DUMMY_CERTIFICATE_URL
    },
    {
        title: "English Level 5 Training Certificate",
        organization: "Organization Name",
        issueDate: "Issued March 2022",
        certificateImage: DUMMY_CERTIFICATE_URL
    },
    {
        title: "English Level 5 Training Certificate",
        organization: "Organization Name",
        issueDate: "Issued March 2022",
        certificateImage: DUMMY_CERTIFICATE_URL
    }
];