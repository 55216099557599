import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../Components/Footer';

const AcademicCourses = () => {
    const navigate = useNavigate();
    const publicUrl = process.env.PUBLIC_URL;

    const heroTitle = "AI-Powered Academic Class Spaces";
    const heroSubtitle = "Personalized learning environments to help you stay organized and excel in your studies.";

    const productDescriptionTitle = "Revolutionize Your Learning with AI";
    const productDescription = `Academic Class Spaces are subject-specific organizational tools that allow students to create, manage, and optimize their learning environment. These spaces are designed to cater to the unique needs, preferences, and interests of each student, helping them stay organized and on top of their studies throughout the academic year or semester. With our AI-driven features, you can transform the way you learn and achieve your academic goals.`;

    const features = [
        {
            title: 'Create Class Spaces',
            description: "Students can create personalized spaces for each of their academic subjects. Stay organized and allow your Class Space's AI learn about your specific class by uploading class notes, slides, and other materials throughout the semester or year. By understanding your professor's unique teaching style, our AI ensures you are studying the right material and not wasting time on irrelevant information."
        },
        {
            title: 'Generate Personalized Study Guides',
            description: 'Create study guides to review and better understand complex or confusing class topics. Link your uploaded materials to ensure the content is relevant to your class. Customize your guides to match your learning style, optimize them to your current level of understanding, and personalize them by incorporating contexts that reflect your hobbies and interests.'
        },
        {
            title: 'Practice Modules',
            description: 'Use our integrated modules to quiz yourself as much as you’d like. Receive immediate feedback on each question and, if needed, generate study guides to reinforce prerequisite knowledge. These modules are tailored to your current level and can be customized to focus on specific topics or concepts. Use them to prepare for exams, review class material, or strengthen your understanding. The more you engage, the better our AI learns about your strengths and weaknesses, providing you with more targeted support.'
        },
        {
            title: 'Chat with Your AI Tutor',
            description: 'Get instant help and feedback on your studies by interacting with your AI tutor. Available 24/7, the AI tutor provides personalized guidance to improve your understanding and performance. Ask questions, receive explanations, and get support whenever you need it. Receive hints without giving away the answers, and get explanations tailored to your level of understanding. Highlight specific lines or sections of your study materials for targeted feedback and explanations.'
        },
        {
            title: 'Tutor-Integrated',
            description: "IAMAI's Academic Class Spaces can be purchased and used as a standalone, self-serve product, or in conjunction with our tutoring service. All of our tutors are trained to use our AI tools and can provide additional support and guidance to help you excel in your studies. By linking your tutor's account, you can receive personalized assignments and feedback to further enhance your learning experience."
        },
        {
            title: 'Parent Account Linking',
            description: "Parents can link their accounts with their children's to monitor study habits, performance, and engagement with both human and AI tutors. This feature allows parents to provide additional academic support. Parents can choose to receive notifications or disable them if they prefer to let their children manage their accounts independently."
        }
    ];

    const benefits = [
        {
            title: 'Personalized Learning',
            description: 'Tailored study guides and practice modules ensure that students receive the support they need at their level of understanding.'
        },
        {
            title: 'Organized Learning Environment',
            description: 'Students can keep all their class materials in one place, making it easier to study and stay on track.'
        },
        {
            title: 'Enhanced Engagement',
            description: 'By incorporating students’ interests into the study materials, IAMAI keeps students engaged and motivated.'
        },
        {
            title: 'Improved Performance',
            description: 'Regular practice and immediate feedback help students improve their understanding and academic performance.'
        }
    ];

    const subjectsTitle = "Specialized Subject Spaces";
    const subjectsDescription = "We offer dedicated spaces for various subjects, each equipped with specialized tools to enhance your learning experience.";

    const subjects = [
        {
            title: 'Math',
            description: 'Utilize our specialized math tools to tackle complex problems and improve your mathematical understanding.',
            img: publicUrl + '/images/math-tools.jpg'
        },
        {
            title: 'History',
            description: 'Dive deep into historical events and timelines with our comprehensive history resources.',
            img: publicUrl + '/images/history-tools.jpg'
        },
        {
            title: 'Science',
            description: 'Explore scientific concepts and conduct virtual experiments in our science spaces.',
            img: publicUrl + '/images/science-tools.jpg'
        },
        {
            title: 'English',
            description: 'Enhance your language skills with our English resources, including grammar tools, literature guides, and essay writing tips.',
            img: publicUrl + '/images/english-tools.jpg'
        }
    ];

    const trialDescription = "Sign up today for a 14-day free trial to experience the full potential of our Academic Class Spaces. Discover how personalized learning can make a difference in your educational journey.";

    return (
        <div className="flex-container">
            {/* <Header /> */}
            <div className="content">
                {/* Hero Section */}
                <Box
                    sx={{
                        position: 'relative',
                        color: 'white',
                        textAlign: 'center',
                        py: 10,
                        backgroundImage: `url(${publicUrl}/images/academic-hero.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'brightness(100%)',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        }}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h2" gutterBottom>{heroTitle}</Typography>
                        <Typography variant="h6">
                            {heroSubtitle}
                        </Typography>
                    </Box>
                </Box>

                {/* Product Description */}
                <Container sx={{ py: 8 }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="h4" gutterBottom>{productDescriptionTitle}</Typography>
                        <Typography variant="body1">
                            {productDescription}
                        </Typography>
                    </Box>

                    {/* Features Section */}
                    <Grid container spacing={4}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ textAlign: 'center' }}>
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>{feature.title}</Typography>
                                        <Typography variant="body2">
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Benefits Section */}
                    <Box sx={{ textAlign: 'center', mt: 8 }}>
                        <Typography variant="h4" gutterBottom>Benefits of Using IAMAI</Typography>
                        <Grid container spacing={4}>
                            {benefits.map((benefit, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card sx={{ textAlign: 'center' }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>{benefit.title}</Typography>
                                            <Typography variant="body2">
                                                {benefit.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Subjects Section */}
                    <Box sx={{ textAlign: 'center', mt: 8 }}>
                        <Typography variant="h4" gutterBottom>{subjectsTitle}</Typography>
                        <Typography variant="body1" mb={4}>
                            {subjectsDescription}
                        </Typography>
                        <Grid container spacing={4}>
                            {subjects.map((subject, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card sx={{ textAlign: 'center' }}>
                                        <CardContent>
                                            <img src={subject.img} alt={subject.title} style={{ width: '100%', borderRadius: '8px', marginBottom: '16px' }} />
                                            <Typography variant="h5" gutterBottom>{subject.title}</Typography>
                                            <Typography variant="body2">
                                                {subject.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Trial Section */}
                    <Box sx={{ textAlign: 'center', mt: 8 }}>
                        <Typography variant="h4" gutterBottom>Try Our 14-Day Free Trial</Typography>
                        <Typography variant="body1" mb={4}>
                            {trialDescription}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => navigate('/signup')}>
                            Sign Up for Free Trial
                        </Button>
                    </Box>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default AcademicCourses;
