import React from 'react';
import { Link } from 'react-router-dom';
import ShadowBtn from '../../../Components/ShadowBtn';

const TutorCard = ({ data, bookAppointment }) => {
    return (
        <div className=" mx-auto bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
            <img
                src={data?.imageUrl}
                alt="Tutor"
                className="w-full h-48 rounded-b-lg object-cover"
            />
            <div className="p-4">
                <div className="flex justify-between items-center mb-3">
                    <h3 className="text-2xl leading-9 font-semibold text-[#012E33] fredoka">{data?.tutor_name}</h3>
                    <span className="text-2xl leading-9 text-[#012E33] fredoka">${data?.tutor_hourly_rate}/hr</span>
                </div>

                <div className="flex items-center gap-3 mb-2">
                    <div className=' flex items-center gap-2'>
                        <img src="/images/icons/GraduationCap.svg" alt='graduationcap' className="h-6 w-6" />
                        <span className="text-[#678285] text-sm leading-[21px] fredoka">{data?.subjects}</span>
                    </div>
                    <div className=' flex items-center gap-2'>
                        <img src="/images/icons/star.svg" alt='star' className="h-6 w-6" />
                        <span className="text-[#678285] text-sm leading-[21px] fredoka">{data?.rating}/5</span>

                    </div>
                </div>

                <p className="text-[#678285] fredoka text-sm leading-[21px] mb-2">
                    {data?.tutor_bio}
                </p>

                <button className="text-[#678285] fredoka text-sm leading-[21px] underline mb-[22px]">
                    Read More
                </button>

                <Link to={"/tutor-details"} state={{ tutorDetails: data }} className="block w-full py-2 mb-2 text-[#FC823E] border fredoka border-[#FC823E] rounded-2xl font-semibold text-center">
                    Learn More
                </Link>

                <ShadowBtn
                    className='w-full'
                    text='Book Free Session'
                    onClick={() => bookAppointment(data?.tutor_name)}
                />
            </div>
        </div>
    );
};

export default TutorCard;
