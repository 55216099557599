import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';

const testimonials = [
    {
        id: 1,
        text: "We are delighted with the results IAMAI has delivered for our child. Their platform is amazing and very easy to use. ",
        author: "John Doe.",
        role: "Parent"
    },
    {
        id: 2,
        text: "I found the personalized attention extremely beneficial for my learning.",
        author: "Sarah M.",
        role: "Student"
    },
    {
        id: 3,
        text: "The progress my daughter has made is remarkable. Highly recommended!",
        author: "Parent of Emma W.",
        role: "Parent"
    }
];

const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    };

    const previousSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="w-full container !mt-14 mx-auto px-4 py-12">
            <div className="space-y-6 text-center md:text-left max-w-xl">
                <h2 className="text-[#012E33] text-center md:text-left fredoka text-[40px] font-medium leading-normal tracking-[-0.8px]">
                    See what parents and students say about us
                </h2>
                <p className="text-[#678285] text-center md:text-left font-[Fredoka] text-[22px] font-normal leading-[32px] tracking-[-0.22px]"
                >
                    Join our community of successful students and happy parents.
                </p>
                <div className="relative mt-8 flex flex-col md:items-start items-center">
                    <div className="overflow-hidden">
                        <div className="min-h-[200px] bg-white p-6 rounded-lg shadow-sm">
                            <p className="text-lg text-slate-700 mb-4">
                                {testimonials[currentIndex].text}
                            </p>
                            <div className="text-sm flex w-full justify-center md:justify-start items-center gap-4 text-slate-600">
                                <div className='rounded-md overflow-hidden'>
                                    <img src="/images/person.png" alt="" />
                                </div>
                                <div>
                                    <p className="font-semibold">{testimonials[currentIndex].author}</p>
                                    <p>{testimonials[currentIndex].role}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-4 mt-4">
                        <button
                            onClick={previousSlide}
                            className="rounded-[32px] border border-[#D9DFDF] inline-flex p-[16px] flex-col justify-center items-center gap-[8px]"

                            aria-label="Previous testimonial"
                        >
                            <ChevronLeft />
                        </button>
                        <button
                            onClick={nextSlide}
                            className="rounded-[32px] border border-[#D9DFDF] inline-flex p-[16px] flex-col justify-center items-center gap-[8px]"

                            aria-label="Next testimonial"
                        >
                            <ChevronRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;