import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Footer } from '../Components/Footer';

const publicUrl = process.env.PUBLIC_URL;
const imageUrl = `${publicUrl}/images/space.webp`; // Change this link to your image URL

export default function ComingSoon() {
    return (
        <div>
            {/* <Header /> */}
            <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '8vh', marginBottom: '8vh' }}>
                <Box>
                    <Typography variant="h2" component="h1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                        Coming Soon
                    </Typography>
                    <Box
                        style={{
                            width: '100%',
                            height: '300px',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px'
                        }}
                    >
                        <img src={imageUrl} alt="Coming Soon" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </Box>
                    <Typography variant="h6" component="p" style={{ color: '#888' }}>
                        Stay tuned! We are working hard to bring you something amazing.
                    </Typography>
                </Box>
            </Container>
            <Footer />
        </div>
    );
}
