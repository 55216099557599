import React from 'react';

const navigation = {
    solutions: [
        { name: 'Academic Class Spaces', href: '#' },
        { name: 'College Counselling', href: '#' },
        { name: 'Standardized Testing', href: '#' },
    ],
    tutors: [
        { name: 'Browse Tutors', href: '#' },
        { name: 'Spanish Tutors', href: '#' },
        { name: 'Math Tutors', href: '#' },
        { name: 'History Tutors', href: '#' },
        { name: 'English Tutors', href: '#' },
        { name: 'Apply as Tutor', href: '#' },
    ],
    company: [
        { name: 'About Us', href: '#' },
        { name: 'Careers', href: '#' },
        { name: 'Partnerships', href: '#' },
        { name: 'Privacy Policy', href: '#' },
        { name: 'Terms of Services', href: '#' },
    ],
    support: [
        { name: 'Contact Us', href: '#' },
        { name: 'Email Us', href: '#' },
        { name: 'Request a Feature', href: '#' },
    ],
};

const socials = [
    { name: 'LinkedIn', icon: '/social-media/LinkedinLogo.svg', href: '#' },
    { name: 'Facebook', icon: '/social-media/FacebookLogo.svg', href: '#' },
    { name: 'Twitter', icon: '/social-media/XLogo.svg', href: '#' },
    { name: 'Instagram', icon: '/social-media/InstagramLogo.svg', href: '#' },
]

const Footer = () => {
    return (
        <footer className="bg-white py-6">
            <div className="max-w-7xl mx-auto flex flex-col px-4">
                {/* Logo and Tagline */}
                <div className="flex items-center gap-5">
                    <img src="/images/logo.svg" alt="logo" className="w-[189px] h-auto object-contain" />
                    <h2 className="text-lg -tracking-[0.18px] font-medium leading-[32px] text-[#044047] italic inter">
                        I am always improving 🚀
                    </h2>
                </div>

                {/* Navigation Grid */}
                <div className="flex flex-col md:flex-row justify-between items-start gap-8 md:gap-0">
                    {/* Navigation Sections */}
                    <div className="grid grid-cols-2 text-left gap-8 sm:flex sm:gap-12 sm:items-start">
                        {Object.keys(navigation).map((key) => (
                            <FooterSection key={key} title={key} contents={navigation[key]} />
                        ))}
                    </div>

                    {/* Newsletter */}
                    <div className="flex flex-col w-full max-w-[343px] justify-center bg-[linear-gradient(136deg,#FFF_8.91%,#F7F7F7_50%)] gap-4 p-6 rounded-3xl order-2 lg:order-none">
                        <h3 className="font-medium text-[#044047] text-lg leading-[24px] -tracking-[0.18px] capitalize">
                            Subscribe to our Newsletter<br />
                            for our latest updates
                        </h3>
                        <div className="flex flex-col gap-4">
                            <input
                                type="email"
                                placeholder="Enter your Email Address"
                                className="w-full text-base font-medium -tracking-[0.16px] px-[18px] py-[14px] rounded-2xl border border-[#DCDCDC]"
                            />
                            <button className="py-[14px] px-6 rounded-2xl border border-[#66CDCC] text-[#66CDCC] text-base">
                                Subscribe
                            </button>
                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="flex flex-col md:flex-row justify-between items-center gap-6 pt-9">
                    <p className="text-[#678285] font-normal text-base leading-[32px] -tracking-[0.16px]">
                        © All rights reserved 2024
                    </p>
                    <div className="flex flex-col md:flex-row md:items-center md:gap-7">
                        <span className="text-[#678285] font-normal text-base leading-[32px] -tracking-[0.16px]">
                            Connect with us
                        </span>
                        <div className="flex gap-6">
                            {socials.map((social) => (
                                <a key={social.name} href={social.href} className="w-8 h-8 flex">
                                    <img src={social.icon} alt={social.name} className="w-full h-full object-contain" />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
};

function FooterSection({ title, contents }) {
    return (
        <div className='flex flex-col gap-[6px] pt-6'>
            <h3 className="font-medium text-[#044047] text-md leading-[32px] -tracking-[0.18px] capitalize">{title}</h3>
            <ul className="flex flex-col gap-[6px]">
                {contents.map((item) => (
                    <li key={item.name}>
                        <a href={item.href} className="font-normal text-base text-[#678285] leading-8 -tracking-[0.16px] capitalize">
                            {item.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Footer;