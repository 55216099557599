import React from 'react';
import { SCHEDULE_DATA } from '../constants/Availability';

const TimeSlot = ({ time }) => (
    <div className="px-4 py-2 rounded-[32px] border border-[#66CDCC]">
        <span className="text-[#678285] text-base leading-normal font-normal -tracking-[0.32px]">{time}</span>
    </div>
);

const DaySchedule = ({ day, timeSlots }) => (
    <div className="flex gap-2">
        <span className="text-[#012E33] pt-2 min-w-16 text-base leading-normal font-normal -tracking-[0.32px]">{day}:</span>
        <div className="flex flex-wrap gap-2">
            {timeSlots.map((time, index) => (
                <TimeSlot key={index} time={time} />
            ))}
        </div>
    </div>
);

const Availability = () => {


    return (
        <div className="flex flex-col gap-4">
            {SCHEDULE_DATA.map((schedule, index) => (
                <DaySchedule
                    key={index}
                    day={schedule.day}
                    timeSlots={schedule.timeSlots}
                />
            ))}
        </div>
    );
};

export default Availability;